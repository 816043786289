/**
 *
 * Click and Collect javascript
 *
 * @author Fabio Polizzi  <dpocina[at]kooomo[dot]com>
 *
 */

/* global _, JS_TRANSLATIONS, handlebarsTemplates, zgPost */

(function ( $, _ ) {
    'use strict';

    // Establish the root object ('window' in the browser)
    var root = this;

    /**
     * @selector
     */
    var SELECTOR = '[data-zg-role="product"],[data-zg-role="select-shipping-method"]';


    var DEFAULTS = {
        resetWarehouseBtn: '[data-zg-role="resetSetWarehouse"]',

    };


    // GETUPSACCESSPOINTS CLASS DEFINITION
    // ===================================

    /**
     * @param {HTMLElement} element
     * @param {!Object}     options
     *
     * @constructor
     */
    var ClickAndCollect = function ( element, options ) {
        this.$element = $( element );
        this.options  = options;

        this.$resetWarehouseBtn = this.$element.find( this.options.resetWarehouseBtn );

        this.__setEventHandlers();
    };




    ClickAndCollect.prototype.__resetWarehouseShipping = function () {
        var data = {
            'code': window.SGL_JS_MERCHANT_ID + '_0'
        };
        zgPost( 'setCheckoutWarehouse', data,
            { error: '' },
            {
                success: _.bind( function(response) {
                    location.reload();
                }, this),
                error: _.bind( function(response) {

                }, this)
            }
        );
    };


    ClickAndCollect.prototype.__setWarehouseByStoreCode = function ( storeId, store ) {

        var data;

        if ( !storeId){
            data = {
                'code': window.SGL_JS_MERCHANT_ID + '_0',
            };
        }
        else if( store.fields.StoreId == 0 ){
            data = {
                'code': window.SGL_JS_MERCHANT_ID + '_' + store.fields.StoreId,
            };
        }
        else {
            data = {
                'code': window.SGL_JS_MERCHANT_ID + '_' + store.fields.StoreId,
                'ship_address': {
                    store_id: 			store.fields.StoreId || '',
                    countryCode: 		store.fields.CountryCode || '',
                    stateCode: 			store.fields.ProvinceCode || '',
                    addr_1: 			store.fields.Street || '',
                    addr_2: 			store.fields.Name || '',
                    city: 				store.fields.City || '',
                    phone: 				store.fields.Phone || '',
                    post_code: 			store.fields.ZipCode || ''
                }
            };
        }

        zgPost( 'setCheckoutWarehouse', data,
            { error: '' },
            {
                success: _.bind( function(response) {

                    var $product = this.$element.closest('[data-zg-role="product"]'),
                        zgProduct = $product.data('zg.product'),
                        $storeName = $product.find('[data-zg-role="selectedStoreName"]'),
                        $storeNameValue = $product.find('[data-zg-role="selectedStoreNameValue"]'),
                        $resetSetWarehouse = $product.find('[data-zg-role="resetSetWarehouse"]');

                    if (zgProduct){
                        if( zgProduct.options.checkQuantity ){
                            zgProduct.checkAvailability();
                        }
                        if( response.code == 0 || response.code === window.SGL_JS_MERCHANT_ID + '_0' ){
                          $resetSetWarehouse.addClass('hidden');
                          $storeName.addClass('hidden');
													$('.availability').addClass('hidden');
													$('.stock-home-delivery').removeClass('hidden');
													$('.stock-click-and-collect').addClass('hidden');
                        }
                        else {
                          $resetSetWarehouse.removeClass('hidden');
                          $storeName.removeClass('hidden');
													$('.availability').removeClass('hidden');
													$('.stock-home-delivery').addClass('hidden');
													$('.stock-click-and-collect').removeClass('hidden');
                          $storeNameValue.html( store.fields.Name + ' - ' + store.fields.Street );
                        }
                    }
									
										$( '[data-zg-role="pickUp-form"]' ).submit();


                }, this),
                error: _.bind( function(response) {
                    var zgProduct = this.$element.closest('[data-zg-role="product"]').data('zg.product');
                    if( zgProduct.options.checkQuantity ){
                        zgProduct.checkAvailability();
                    }
                }, this)
            }
        );
    };




    // EVENT HANDLERS
    // ------------------------------------------------------------------------


    /**
     *
     * @private
     */
    ClickAndCollect.prototype.__setEventHandlers = function () {

        this.$resetWarehouseBtn.on( 'click', (function ( e ) {
          e.preventDefault();
					this.__setWarehouseByStoreCode();
        }).bind( this ) );

        $(document).on( 'zg.selectShipping.shippingReady',  (function ( e, shippingId, shippingInfo ) {
            if(window.ZG_CONFIG.collectInStore === true && shippingInfo.isPickUpInStore === false){
                this.__resetWarehouseShipping();
            }
        }).bind( this ) );

        $(document).on( 'zg.storeLocator.selectedMarker',  (function ( e,storeId, store ) {
            if(storeId) this.__setWarehouseByStoreCode( storeId, store );
        }).bind( this ) );



    };


    // GETUPSACCESSPOINTS PLUGIN DEFINITION
    // ====================================

    function Plugin ( option ) {
        return this.each( function () {
            var $this   = $( this );
            var data    = $this.data( 'zg.clickAndCollect' );
            var options = $.extend( {}, root.ZG_CONFIG || {}, DEFAULTS, $this.data(), typeof option === 'object' && option );

            if ( !data ) {
                $this.data( 'zg.clickAndCollect', (data = new ClickAndCollect( this, options )) );
            }
        } );
    }

    $.fn.clickAndCollect             = Plugin;
    $.fn.clickAndCollect.Constructor = ClickAndCollect;


    // GETUPSACCESSPOINTS DATA-API
    // ===========================

    // default product - called on page load
    $( function () {
        if( ZG_CONFIG.collectInStoreActive == true ) {
            $(SELECTOR).each(function () {
                Plugin.call($(this));
            });
        }
    } );

}.call( this, jQuery, _ ));