/**
 *
 * When edit billing address, show (and make required) or hide invoice data
 * @author David Pocina  <dpocina[at]kooomo[dot]com>
 *
 */

/**
 * @event document#zg.addressForm.ready The address form has been rendered. Contains the address information.
 * @type {object}
 * @property {object} Address - Send to the event the address object received by backend. See zg-address-actions for details
 */

/**
 * @event document#change.zg.address Checkbox for request invoice change value
 * @type {null}
 */



(function ( $ ) {
	'use strict';

	/**
	 * @selector data-zg-role="request-invoice" When the SELECTOR (checkbox) change value, we call the function changeInvoiceStatus with value of the checkbox
	 */
	var SELECTOR      = '[data-zg-role="request-invoice"]',
		invoiceDataContainer = '[data-zg-role="request-invoice-container"]',
		invoiceDataSelector  = '[data-zg-role="request-invoice-data"]';


	function initInvoiceStatus () {
		$( SELECTOR ).each( function () {
			changeInvoiceStatus( this );
		} );
	}


	function changeInvoiceStatus ( item ) {
		var $selector  = $( item ),
			$form      = $selector.closest( 'form' ),
			$container = $form.find( invoiceDataContainer ),
			$input     = $form.find( invoiceDataSelector );

		if ( $selector.is( ':checked' ) ) {
			$container.removeClass( 'hidden' ).fadeIn();
			$input.prop( 'required', true ).prop( 'disabled', false );
		} else {
			$container.hide();
			$input.prop( 'required', false ).prop( 'disabled', true );
		}
	}


	$( function () {

		/**
		 * @method document
		 * @listen change.zg.address On checkbox for request invoice change, call changeInvoiceStatus function
		 */
		$( document ).on( 'change.zg.address', SELECTOR, function () {
			changeInvoiceStatus( this );
		} );

		/**
		 * @method document
		 * @listen zg.addressForm.ready On address form ready init the function
		 */
		$( document ).on( 'zg.addressForm.ready', initInvoiceStatus );
		initInvoiceStatus();
	} );

})( jQuery );
