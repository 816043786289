/**
 *
 * Manage fidalty card in account and cart page
 *
 * @author
 *
 */

/**
 * @event document#zg-error Generic error. Used by 2002-zg-notifier.js to display the error
 * @type {object}
 * @property {string} eventType - Typology of event error.
 * @property {string} message - The error message to be translated.
 */

(function ( $ ) {

	"use strict";

	/**
	 * @selector data-zg-role="fidelity" The plugin start if there is the selector in the dom when the page load
	 */

	var toggle = '[data-zg-role="fidelity"]';
	var getFidelityInfo = '[data-zg-role="get-fidelity-info"]';
	var getPrintableCard = '[data-zg-role="get-printable-card"]';
	var getPrintableCardSubmit = '[data-zg-role="get-printable-card-submit"]';
	var linkFidelityCardSubmit = '[data-zg-role="link-fidelity-card-submit"]';
	var fidelityCardInfoRefreshSubmit = '[data-zg-role="fidelity-card-info-refresh-submit"]';
	var use_fidelity_card_checkbox = '[data-zg-role="use-fidelity-card-checkbox"]';
	var useFidelityPointsSubmit = '[data-zg-role="use-fidelity-points-submit"]';
	var registerFidelityCardSubmit = '[data-zg-role="register-fidelity-card-submit"]';
	var checkFidelityCardStatus = '[data-zg-role="check-fidelity-card-status"]';
	var deleteFidelityDiscount = '[data-zg-role="delete-fidelity-discount"]';
	var fidelityUsingPoints = '[data-zg-role="fidelity-using-points"]';
	var fidelityFormSubmit = '[data-zg-role="fidelity-form-submit"]';
	var fidelityFormSubmitBtn = '[data-zg-role="fidelity-btn-submit"]';
	var fidelityCardCode = '[data-zg-role="fidelity-code"]';
	var fidelityUsablePoints = '[data-zg-role="fidelity-usable-points"]';
	var fidelityUsableValue = '[data-zg-role="fidelity-usable-value"]';
	var fidelityTotalPoints = '[data-zg-role="fidelity-total-points"]';
	var fidelityTotalValue = '[data-zg-role="fidelity-total-value"]';
	
	
	var ZG_Fidelity = function ( element, config ) {
		var self = this;
		
		this.init = function ( element, config ) {
			var $element = $(element);
			self.$element = $element;
			self.$eventBus = $(document);

			self.$getFidelityInfo = self.$element.find( getFidelityInfo );
			self.$getPrintableCard = self.$element.find( getPrintableCard );
			self.$getPrintableCardSubmit = self.$element.find( getPrintableCardSubmit );
			self.$linkFidelityCardSubmit = self.$element.find( linkFidelityCardSubmit );
			self.$fidelityCardInfoRefreshSubmit = self.$element.find( fidelityCardInfoRefreshSubmit );
			self.$use_fidelity_card_checkbox = self.$element.find( use_fidelity_card_checkbox );
			self.$useFidelityPointsSubmit = self.$element.find( useFidelityPointsSubmit );
			self.$registerFidelityCardSubmit = self.$element.find( registerFidelityCardSubmit );
			self.$checkFidelityCardStatus = self.$element.find( checkFidelityCardStatus );
			self.$deleteFidelityDiscount = self.$element.find( deleteFidelityDiscount );
			self.$fidelityUsingPoints = self.$element.find( fidelityUsingPoints );
			self.$fidelityFormSubmitBtn = self.$element.find( fidelityFormSubmitBtn );
			self.$fidelityFormSubmit = self.$element.find( fidelityFormSubmit );
			self.$fidelityCardCode = self.$element.find( fidelityCardCode );
			self.$fidelityUsablePoints = self.$element.find( fidelityUsablePoints );
			self.$fidelityUsableValue = self.$element.find( fidelityUsableValue );
			self.$fidelityTotalPoints = self.$element.find( fidelityTotalPoints );
			self.$fidelityTotalValue = self.$element.find( fidelityTotalValue );

			self.$getFidelityInfo.on('click', function ( event ) {
				event.preventDefault();
				self.getFidelityInfo();
				return false;
			});
			
			self.$getPrintableCard.on('click', function ( event ) {
				event.preventDefault();
				self.getPrintableCard();
				return false;
			});
			self.$getPrintableCardSubmit.on('click', function ( event ) {
				event.preventDefault();
				self.getPrintableCardSubmit();
				return false;
			});
			self.$linkFidelityCardSubmit.on('submit.zg.linkFidelityCardForm', function ( event ) {
				event.preventDefault();
				self.linkFidelityCardSubmit();
				return false;
			});
			
			self.$fidelityCardInfoRefreshSubmit.on('click', function ( event ) {
				event.preventDefault();
				self.fidelityCardInfoRefreshSubmit();
				return false;
			});

			self.$use_fidelity_card_checkbox.on('click', function ( event ) {
				//event.preventDefault();
				self.use_fidelity_card_checkbox();
				//return false;
			});

			self.$useFidelityPointsSubmit.on('click', function ( event ) {
				event.preventDefault();
				self.useFidelityPointsSubmit();
				return false;
			});

			self.$fidelityFormSubmit.on('submit', function ( event ) {
				event.preventDefault();
				self.useFidelityPointsSubmit();
				//self.userFidelityPointsFormCheck() && self.useFidelityPointsSubmit();
			});

			self.$registerFidelityCardSubmit.on('click', function ( event ) {
				event.preventDefault();
				self.registerFidelityCardSubmit();
				return false;
			});
			self.$checkFidelityCardStatus.on('click', function ( event ) {
				event.preventDefault();
				self.checkFidelityCardStatus();
				return false;
			});
			
			self.$deleteFidelityDiscount.on('click', function ( event ) {
				event.preventDefault();
				self.deleteFidelityDiscount();
				return false;
			});
			
			self.$fidelityUsingPoints.on('change', function( event ) {
				if(event.target.value.length > 0 && +event.target.value > 0){
					self.$fidelityFormSubmitBtn.attr("disabled", false);
				} else {
					self.$fidelityFormSubmitBtn.attr("disabled", true);
				}
			});
		};

		/**
		 * @method getFidelityInfo
		 * @fires document#zg-error Display the error if ajax call for fidality info not return success
		 */

		this.getFidelityInfo = function () {
	        var url = window.makeUrl({module: 'eshop', manager: 'fidelity', action: 'getFidelityInfo'});
	        var cardCode = $('#fidelity-getFidelityInfo-cardCode').val();    
	        $.ajax({
	    		    type: 'post',
	    		    url: url,
	    		    dataType: 'json',
	    		    data: { 'cardCode': cardCode },
	    		    success: function (response) {
								
	    		        if (response && response.status === "success"){
	    		        	$(".fidelity-getFidelityInfo-response").html(
	    		        	"fidelity card number: " + response.data.code + "<br>" +
	    		        	"available points: " + response.data.usablepoints + "<br>" +
	    		        	"available value: " + response.data.usablevalue + "<br>"+
	    		        	"total points: " + response.data.totalpoints + "<br>" +
	    		        	"total value: " + response.data.totalvalue + "<br>"
	    		        	);
	    		        } else {
	    		        	$(".fidelity-getFidelityInfo-response").html(response.message);
	    	                $(document).trigger('zg-error', [{
	    	                    eventType: 'fidelity-getFidelityInfo',
	    	                    message: (response.message)
	    	                }]);
	    		        	
	    		        }
	    		    },
	    	        error: function () {
		                $(document).trigger('zg-error', [{
		                    eventType: 'fidelity-getFidelityInfo',
		                    message: ('Ajax request failed.')
		                }]);
	    	        }
	        });
	    };

		/**
		 * @method getPrintableCard
		 * @fires document#zg-error Display the error if ajax call for printable card code not return success
		 */

	    this.getPrintableCard = function () {
	        var url = window.makeUrl({module: 'eshop', manager: 'fidelity', action: 'getPrintableCard'});
	        var cardCode = $('#fidelity-getPrintableCard-cardCode').val();
	        $.ajax({
	    		    type: 'post',
	    		    url: url,
	    		    dataType: 'json',
	    		    data: { 'cardCode': cardCode },
	    		    success: function (response) {
	    		        if (response && response.data.status === "success"){
	    		        	$(".fidelity-getPrintableCard-response").html(
	    		        			"fidelity card: " + response.data.card +'</br> <img src="'+ response.data.card +'"/>'
	    		        	);
	    		        } else {
	    		        	$(".fidelity-getPrintableCard-response").html('');
	    	                $(document).trigger('zg-error', [{
	    	                    eventType: 'fidelity-getPrintableCard',
	    	                    message: (response.message)
	    	                }]);
	    		        }
	    		    },
	    	        error: function () {
			        	$(".fidelity-getPrintableCard-response").html('');
		                $(document).trigger('zg-error', [{
		                    eventType: 'fidelity-getPrintableCard',
		                    message: ('Request failed.')
		                }]);
	    	        }
	        });
	    }
	    
	    
	    this.getPrintableCardSubmit = function () {
	    	var frameSrc = "/eshop/fidelity/action/printcard/";
	    	var $modal = $('#fidelityPrintCardSubmitModal');

	    	$modal.on('show.bs.modal', function () {
                    $modal.find('iframe').attr("src",frameSrc);	          
	    	});
	    	
	    	$modal.modal('show');

	    }

		/**
		 * @method linkFidelityCardSubmit
		 * @fires document#zg-error Display the error if ajax call for link fidelity card to the user not return success
		 */

		this.linkFidelityCardSubmit = function () {
			var url = window.makeUrl({module: 'eshop', manager: 'fidelity', action: 'linkCardToAccount'});
			//var url="http://development.zerogrey.com/eshop/fidelity/action/linkCardToAccount/";
			var cardCode 			= $('[data-zg-role="fidelity-card"]').val();
			var email				= $('#emailFidelity').val();
			var phone				= $('#phoneFidelity').val();
			var customfieldSelector	= $('[data-zg-role="customField"]');
			var customTypology 		= '';
			var customtosendfield	= [];
			var customfield			= {};


			if(customfieldSelector.length) {
				customfieldSelector.each( function(key, input) {
					var $input = $(input);
					switch ($input.attr('type')) {
						case('checkbox'):
							if($input.prop('checked'))
								customtosendfield.push({'input':$input.attr('name'),'object_id':$input.data('objectid'),'value':1});
							break;
					}
				});
			}
			
			$.ajax({
				type: 'post',
				url: url,
				dataType: 'json',
				data: { 'cardCode': cardCode ,'name':email, 'lname':phone, customfields:customtosendfield},
				success: function (response) {
					if (response && response.status == "success"){
						sessionStorage.setItem("isfedility",true);
						sessionStorage.setItem("isfedilitycart",true);
						location.reload();
						
						$('#fidelity_link_div').fadeOut('slow');
						$('#fidelity_new_div').fadeOut('slow');
						$('#fidelity-info-cardCode').html(response.data.info.custbarcode);
						self.$fidelityUsablePoints.html(response.data.info.usablepoints);
						self.$fidelityUsableValue.html(response.data.info.usable_currency_value);
						self.$fidelityTotalPoints.html(response.data.info.totalpoints);
						self.$fidelityTotalValue.html(response.data.info.total_currency_value);
						$(".fidelity-linkCardToAccount-response-ok").html(window.JS_TRANSLATIONS['fidelity.cardadded']);
						$('#fidelity_summary_div').fadeIn('slow');
						$('#fidelity_print_div').fadeIn('slow');


					} else {
						$(".fidelity-linkCardToAccount-response").html('');
						$(document).trigger('zg-error', [{
							eventType: 'fidelity-linkCardToAccount',
							message: (response.message)
						}]);

					}
				},
				beforeSend: function () {
					$(".fidelity-linkCardToAccount-response").html(window.JS_TRANSLATIONS['fidelity.loading']);
				},
				error: function () {
					$(".fidelity-linkCardToAccount-response").html('');
					$(document).trigger('zg-error', [{
						eventType: 'fidelity-linkCardToAccount',
						message: ('Request Failed')
					}]);
				}
		    });
	    }

		/**
		 * @method fidelityCardInfoRefreshSubmit
		 * @fires document#zg-error Display the error if ajax call for refresh fidality card info not return success
		 */

		this.fidelityCardInfoRefreshSubmit = function () {
			var url = window.makeUrl({module: 'eshop', manager: 'fidelity', action: 'getFidelityInfo'});
			$.ajax({
			    type: 'post',
			    url: url,
			    dataType: 'json',
			    data: {},
			    success: function (response) {
			        if (response && response.status == "success"){
			        	$(".fidelity-linkCardToAccount-response-ok").html(window.JS_TRANSLATIONS['fidelity.pointupdated']);
			        	self.$fidelityCardCode.html(response.data.code);
			        	self.$fidelityUsablePoints.html(response.data.usablepoints || 0);
			        	self.$fidelityUsableValue.html(response.data.usable_currency_value);
			        	self.$fidelityTotalPoints.html(response.data.totalpoints);
			        	self.$fidelityTotalValue.html(response.data.total_currency_value);

						$(document).trigger('zg-notification', [{
							eventType: 'fidelity-getFidelityInfo',
							message: window.JS_TRANSLATIONS['fidelity.pointsRefreshedOK']
						}]);  
			        } else {
			        	$(".fidelity-linkCardToAccount-response-ok").html('');
		                $(document).trigger('zg-error', [{
		                    eventType: 'fidelity-getFidelityInfo',
		                    message: (response.message)
		                }]);    		        	
			        	
			        }
			    },
		        beforeSend: function () {
		        	$(".fidelity-linkCardToAccount-response-ok").html(window.JS_TRANSLATIONS['fidelity.loading']);
		        },
		        error: function () {
		        	$(".fidelity-linkCardToAccount-response-ok").html('');
		            $(document).trigger('zg-error', [{
		                eventType: 'fidelity-linkCardToAccount',
		                message: ('Ajax request failed.')
		            }]);
		        }
			});
		}


		this.use_fidelity_card_checkbox = function () {

			$('#fidelitybox').slideToggle();

		}


		/**
		 * @method useFidelityPointsSubmit
		 * @fires document#zg-error Display the error if ajax call for use fidality points not return success
		 */

		this.useFidelityPointsSubmit = function () {
			var url = window.makeUrl({module: 'eshop', manager: 'fidelity', action: 'useFidelityPoint'});
			var cardCode = self.$fidelityCardCode.val();
			var point = $(this.$fidelityUsingPoints).val();
			var cartTotal = $('#total_with_shipCost').html();

			$.ajax({
			type: 'post',
			url: url,
			dataType: 'json',
			data: { 'cardCode': cardCode ,'point':point,'cartTotal':cartTotal},
			success: function (response) {
			$(".fidelity-linkCardToAccount-response-ok").html(response.message);
				if (response && response.status === "success"){
				location.reload();
				} else {
				$(".fidelity-linkCardToAccount-response-ok").html('');
				$('#useFidelityPoints').val(response.discountpoints);
						$(document).trigger('zg-error', [{
							eventType: 'fidelity-getFidelityInfo',
							message: (response.message)
						}]);    	        
					
				}
			},
			beforeSend: function () {
				$(".fidelity-linkCardToAccount-response-ok").html(window.JS_TRANSLATIONS['fidelity.creatingpromotion']);
			},
			error: function () {
					$(".fidelity-linkCardToAccount-response-ok").html('');
					$(document).trigger('zg-error', [{
						eventType: 'fidelity-useFidelityPoint',
						message: ('Ajax request failed.')
					}]);
				}
			});	


		};

		this.userFidelityPointsFormCheck = function() {
			var pointsRemaining = +self.$fidelityUsablePoints.text() || 0;
			var points = +self.$fidelityUsingPoints.val();
			var response = true;
			if( points > pointsRemaining ){
				this.validateInputFalse();
				response = false;
			}
			return response;
		}

		this.validateInputFalse = function() {
			var pointsRemaining = parseInt( $('[data-zg-role="fidelity-usable-points"]').text() ) || 0;
			if(pointsRemaining > 0) {
				this.$fidelityFormSubmit.validate({
					rules: {
						useFidelityPoints: {
						required: true,
						max: pointsRemaining
						}
					},
					messages: {
    					useFidelityPoints: "Points exceded.",
  					},
				});
			}
		}


		/**
		 * @method checkFidelityCardStatus
		 * @fires document#zg-error Display the error if ajax call for check fidelity card status not return success
		 */

		this.checkFidelityCardStatus = function () {
			var url = window.makeUrl({module: 'eshop', manager: 'fidelity', action: 'checkFidelityDiscountInfo'});
			var page=$('#checkFidelity').attr('href'); 
			$.ajax({
			        type: 'post',
			        url: url,
			        dataType: 'json',
			        data: {},
			        success: function (response) {
			            if (response && response.status === "success"){
			            	$(location).attr('href',page);
			            }else{
			            	$(document).trigger('zg-error', [{
		                        eventType: 'fidelity-checkFidelityPoint',
		                        message: (response.message)
		                    }]);    
			            	return false;
			            }
			        },
			        error: function () {
			                $(document).trigger('zg-error', [{
			                    eventType: 'fidelity-checkFidelityPoint',
			                    message: ('Ajax request failed.')
			                }]);
			            }
			        });
			return false;
			
		};

		/**
		 * @method checkFidelityCardStatus
		 * @fires document#zg-error Display the error if ajax call for delete fidality discount not return success
		 */

		this.deleteFidelityDiscount = function () {
			var url = window.makeUrl({module: 'eshop', manager: 'fidelity', action: 'deleteFidelityDiscount'});
			 
			$.ajax({
			        type: 'post',
			        url: url,
			        dataType: 'json',
			        data: {},
			        success: function (response) {
			            if (response && response.status === "success"){
			            	location.reload();
			            }else{
			            	$(document).trigger('zg-error', [{
		                        eventType: 'fidelity-deleteFidelityDiscount',
		                        message: (response.message)
		                    }]);    
			            	return false;
			            }
			        },
			        error: function () {
			                $(document).trigger('zg-error', [{
			                    eventType: 'fidelity-deleteFidelityDiscount',
			                    message: ('Ajax request failed.')
			                }]);
			            }
			        });	
		};
		
		this.registerFidelityCardSubmit = function () {
			//$(".account_happiness_form").show();
			
			var url = window.makeUrl({module: 'eshop', manager: 'fidelity', action: 'requestFildelityCard'});
			//var url="http://development.zerogrey.com/eshop/fidelity/action/linkCardToAccount/";
			var cardCode 			= $('[data-zg-role="fidelity-card-phone-prefix"]').val() + $('[data-zg-role="fidelity-card-phone"]').val();
			var card_type = $('#card_type').val();
			var customfieldSelector	= $('[data-zg-role="customField"]');
			var customTypology 		= '';
			var customtosendfield	= [];
			var customfield			= {};			
			var foundb = false;
			var	cardtype = '';
			var customer_address = $('#addr').val() + ', ' +  $('#addr2').val() + ', ' + $('#city').val() + ', ' + $.trim($('#shipping_address_country_id option:selected').text()) + ', ' + $.trim($('#shipping_address_state_id option:selected').text()) + ', ' + $('#shipping_address_post_code').val();
			//console.log(customer_address);
			//alert(customer_address); return false;
				
			if(customfieldSelector.length) {
				customfieldSelector.each( function(key, input) {
					var $input = $(input);
					switch ($input.attr('type')) {						
						case('checkbox'):
							if($input.prop('checked'))
							customtosendfield.push({'input':$input.attr('name'),'value':1});
							cardtype = $input.attr('name');
							break;
						case('text'):
							customtosendfield.push({'input':$input.attr('name'),'value':$input.val()});
							if($input.val()=='')
							{
								foundb = true;
							}
							break;
						case('email'):
							customtosendfield.push({'input':$input.attr('name'),'value':$input.val()});
							if($input.val()=='')
							{
								foundb = true;
							}
							break;
						case('date'):
							customtosendfield.push({'input':$input.attr('name'),'value':$input.val()});
							if($input.val()=='')
							{
								foundb = true;
							}
							break;
					}
				});
			}
			if(foundb==true || cardCode=='')
			{					
				$(".fidelity-linkCardToAccount-response").html('');
				$(document).trigger('zg-error', [{
					eventType: 'fidelity-linkCardToAccount',
					message: ('Please fill all required fields.')
				}]);				
			}
			else{
				
			// hide / show physical card paragraphs in confirmation message
				if(card_type == 1){
					// virtual
					$('.card_physical').hide();
				}else{
					// physical
					$('.card_physical').show();
				}
				
				
				//console.log('mobile no = x' + cardCode + 'x');
				
				
			$('#loader').show();	
			$.ajax({
				type: 'post',
				url: url,
				dataType: 'json',
				data: { loyaltyCustomData: {'mobileNumber':cardCode,'virtual':card_type,'address':customer_address},customfields:customtosendfield},
				success: function (response) {
					
					console.log('status = '+response.tempCardCreated);
					console.log('replacement_flag = '+$('#replacement_flag').val());
					
					if (response && (response.tempCardCreated == true || $('#replacement_flag').val() == 1 ) && cardCode!=''){
						
						console.log('Card creation FINE in Meridian.');
						
						console.log($('#newcard').attr('name'));						
						$('#happinessForm').trigger('click');
						if($('#newcard').attr('name')=='newcard')
						{		
							$('#happiness_send_msg').modal('show');
							setTimeout(function(){
								sessionStorage.setItem("isfedility",true);
								location.reload();
							}, 5000);
							$('#fidelity_link_div').fadeOut('slow');
							$('#fidelity_new_div').fadeOut('slow');							
							$(".fidelity-linkCardToAccount-response-ok").html(window.JS_TRANSLATIONS['fidelity.cardadded']);
							$('#fidelity_summary_div').fadeIn('slow');
							$('#fidelity_print_div').fadeIn('slow');
						}
						else
						{
							$('#loader').hide();
							$(document).trigger('zg-notification', [{
								eventType: 'fidelity-linkCardToAccount',
								message: ('Request has been sent.')
							}]);
							$('#requestCardForm').get(0).reset();
						}
						

					} else {
						$('#loader').hide();
						//console.log('Card creation FAILED in Meridian.');
						$(".fidelity-linkCardToAccount-response").html('');

						let errorMessage = window.JS_TRANSLATIONS["fidelity.request.genericError"];

						if(response.status === "error"){
							errorMessage = window.JS_TRANSLATIONS["fidelity.request.account_already_exist"];
						}
						$(document).trigger('zg-error', [{
							eventType: 'fidelity-linkCardToAccount',
							message: errorMessage
						}]);
						
						alert(errorMessage);
					}
				},
				beforeSend: function () {
					$(".fidelity-linkCardToAccount-response").html(window.JS_TRANSLATIONS['fidelity.loading']);
				},
				error: function () {
					$('#loader').hide();
					$(".fidelity-linkCardToAccount-response").html('');
					$(document).trigger('zg-error', [{
						eventType: 'fidelity-linkCardToAccount',
						message: ('Request Failed')
					}]);
				}
		    });
			}
		};
		
		this.init( element, config );
	}
	
	$.fn.zg_fidelity = function ( config ) {
		return this.each( function () {
			var $this = $(this);
			var data = $this.data('zgFidelity');
			if (!data) {
				$this.data('zgFidelity', (data = new ZG_Fidelity( this, config )) );
			}
		} );
	};

	$(function () {
		$('body').find(toggle).zg_fidelity( window.ZG_CONFIG );
	});
	
})( jQuery );