/**
 *
 * Get list of content (and filters), or single content, by ajax call. Is used in store locator.
 *
 * @author David Pocina  <dpocina[at]kooomo[dot]com>
 *
 */

/**
 * @event document#zg-error Generic error. Used by 2002-zg-notifier.js to display the error
 * @type {object}
 * @property {string} eventType - Typology of event.
 * @property {string} message - The error message.
 */

/**
 * @event document#zg.getContentCMS.start Ajax call for get cms start
 * @type {null}
 */

/**
 * @event document#zg.getContentCMS.complete Ajax call for get cms complete
 * @type {null}
 */

/**
 * @event document#zg.getContentCMS.error Ajax call for get cms make an error
 * @type {null}
 */

/**
 * @event document#zg.getContentCMS.success Ajax call for get cms success
 * @type {object} Contents object
 * @type {object} Filters object
 */

/* global DEBUG */

(function ( $, _ ) {
	'use strict';

	// Establish the root object ('window' in the browser)
	var root = this;

	/**
	 * @selector data-zg-role="get-content-cms" The plugin start if there is the selector in the dom when the page load
	 */
	var SELECTOR = '[data-zg-role="get-content-cms"]';

	// AJAX CALL VALUES

	/**
	 * @param {string} [language] Language of the content (ex "it" or "en")
	 * @param {string} [type] Type of the content (if you want a list of specific type of content)
	 * @param {int} [contentId] Id of the content (if you want a specific content)
	 * @param {int} [isPublished] Specific if you want puplished content (1), not published content (0) or all (null)
	 * @param {int} [limit] Max number or contents for content list
	 * @param {string} [clearCache] Set 1 if you want force cache
	 * @param {int} [contentLinkedToContentId] Set Content id, if you want list of content linked to specific content id
	 * @param {int} [contentLinkedToProductId] Set Product id, if you want list of content linked to specific product id
	 * @param {boolean} [getFilters] Set true if you want filter object
	 * @param {string} [filterBy] Set fields and value if you want filter content list for specific value fields
	 * @param {string} [orderByFieldName] Set specific field name, if you want order the content list for specific field
	 * @param {string} [orderDirection] Set "ASC" or "DESC"
	 * @param {boolean} [forceArray]
	 * @param {boolean} [fakeCurDate]
	 */

	var DEFAULT_REQUEST = {
		//merchantId: null,

		language: null,

		type:      null,
		contentId: null,

		isPublished: null,
		limit:       null,

		clearCache: null,

		contentLinkedToContentId: null,
		contentLinkedToProductId: null,
		//contentLinkedToCategoryId: null,
		//contentLinkedToPromotionId: null,

		getFilters: null,

		filterBy: null,
		//filterByOperator: false,

		//
		orderByFieldName: null,
		orderDirection:   null,
		forceArray:       null,

		fakeCurDate: null
	};


	// SCRIPT OPTIONS

	/**
	 * @param {string}  [filterContainer] Container of the filters html
	 * @param {string}  [paginationContainer] Container of the pagination html
	 * @param {boolean} [forceFilters] Initialize filter plugin even if we didn't request filters. To be used with front end filtering
	 * @param {string}  [initialFilters]
	 */
	var DEFAULTS = {
		filterContainer:     '[data-zg-role="filters"]',
		paginationContainer: '[data-zg-role="pagination"]',
		forceFilters:        false,
		initialFilters:      null
	};


	// GETPRODUCT CLASS DEFINITION
	// ===========================

	/**
	 * @param {HTMLElement} element
	 * @param {!Object}     options
	 *
	 * @constructor
	 */
	var GetContentCMS = function ( element, options ) {
		this.$element = $( element );

		this.requestUrl = root.makeUrl( {module: 'eshop', manager: 'eshop', action: 'getContentCMSWithFilters'} );

		this.options = _.clone( DEFAULTS );
		this.request = _.clone( DEFAULT_REQUEST );
		this.updateOptions( options );

		this.processAppliedFilters( this.options.initialFilters );

		this.$filterContainer     = $( this.options.filterContainer );
		this.$paginationContainer = $( this.options.paginationContainer );

		this.cached         = {};
		this.latestResponse = {};
		this.latestRequest  = null;

		this.__setEventHandlers();
	};


	/**
	 *
	 * @returns {Object}
	 */
	GetContentCMS.prototype.getLatestResponse = function () {
		return this.latestResponse;
	};


	/**
	 *
	 */
	GetContentCMS.prototype.getInfo = function () {
		var cacheKey;

		if ( this.latestRequest ) {
			this.latestRequest.abort();
		}

		this.processRequest();

		if ( this.request.contentId || this.request.type ) {
			cacheKey = JSON.stringify( this.request );

			if ( this.cached[cacheKey] ) {
				this.onBeforeSend();
				this.onSuccess( this.cached[cacheKey] );
				this.onComplete();
			} else {
				if (this.request.type == 'Stores') this.request.filterBy = "IsPickUpInStore[1]";
				this.latestRequest = $.ajax( {
					type:     'GET',
					url:      this.requestUrl,
					dataType: 'json',
					data:     this.request,

					beforeSend: (function () {
						this.onBeforeSend();
					}).bind( this ),

					success: (function ( response ) {
						this.onSuccess( response );
						this.cached[cacheKey] = response;
					}).bind( this ),

					error: (function ( response ) {
						this.onError( response );
					}).bind( this ),

					complete: (function () {
						this.onComplete();
					}).bind( this )
				} );
			}
		} else if ( DEBUG ) {
			console.warn( 'GetContentCMS.getInfo - not requested: contentId && type missing' );
		}
	};


	/**
	 *
	 * @returns {boolean}
	 */
	GetContentCMS.prototype.hasRequestedFilters = function () {
		return !!(this.options.forceFilters || this.request.getFilters);
	};


	/**
	 * @method onBeforeSend
	 * @fires document#zg.getContentCMS.start Before ajax call add class loading
	 */
	GetContentCMS.prototype.onBeforeSend = function () {
		this.$element.addClass( 'loading' );

		$( document ).trigger( 'zg.getContentCMS.start' );
	};


	/**
	 * @method onComplete
	 * @fires document#zg.getContentCMS.complete When ajax call was complete remove class loading
	 */
	GetContentCMS.prototype.onComplete = function () {
		this.$element.removeClass( 'loading' );

		$( document ).trigger( 'zg.getContentCMS.complete' );
	};


	/**
	 * @method onError
	 * @fires document#zg.getContentCMS.error Ajax call make an error
	 */

	/**
	 * @method onError
	 * @fires document#zg-error If ajax call make error notifie the error
	 */
	GetContentCMS.prototype.onError = function ( response ) {
		if ( root.DEBUG ) {
			console.log( 'GetContentCMS - ERROR', response );
		}

		$( document ).trigger( 'zg-error', [{
			eventType: 'GetContentCMS',
			message:   root.JS_TRANSLATIONS.genericErrorMsg
		}] );

		$( document ).trigger( 'zg.getContentCMS.error' );
	};


	/**
	 * @method onSuccess
	 * @fires document#zg.getContentCMS.success If ajax call success trigger the event with response.contents and response.filters
	 */
	GetContentCMS.prototype.onSuccess = function ( response ) {
		if ( root.DEBUG ) {
			console.log( 'GetContentCMS - SUCCESS', response );
		}

		if ( this.hasRequestedFilters() ) {
			this.$filterContainer.zg_filters( this.options, response.filters, response.contents || [] );
		} else {
			this.$paginationContainer.zg_pagination( this.options, response.contents || [] );
		}

		this.latestResponse = response;

		$( document ).trigger( 'zg.getContentCMS.success', [response.contents, response.filters] );
		this.$element.trigger( 'zg.getContentCMS.success', [response.contents, response.filters] );
	};


	/**
	 *
	 * @param appliedFilters
	 */
	GetContentCMS.prototype.processAppliedFilters = function ( appliedFilters ) {
		var filterBy = {};
		var query    = null;
		var param;
		var value;

		for ( param in appliedFilters ) {
			if (
				appliedFilters.hasOwnProperty( param ) &&
				_.contains( this.request.getFilters, param ) &&
				appliedFilters[param] !== null &&
				appliedFilters[param] !== ""
			) {
				value = appliedFilters[param];

				if ( typeof value === 'string' ) {
					value = value.split( ',' );
				}

				filterBy[param] = value;
			}
		}
		
		if ( !_.isEmpty( filterBy ) ) {
			query = decodeURIComponent( JSON.stringify( filterBy ) );
		}

		this.request.filterBy = query;
	};


	/**
	 *
	 */
	GetContentCMS.prototype.processRequest = function () {
		var request = {};
		var param;

		for ( param in this.request ) {
			// Filter by values by the default properties and not null values.
			// We don't want to create an unnecessarily big request
			if (
				this.request.hasOwnProperty( param ) &&
				DEFAULT_REQUEST.hasOwnProperty( param ) &&
				this.request[param] !== null &&
				this.request[param] !== ""
			) {
				request[param] = this.request[param];
			}
		}

		if ( request.orderByFieldName ) {
			if ( !request.orderDirection ) {
				request.orderDirection = 'asc';
			}

			request.forceArray = true;
		}

		this.request = request;
	};


	/**
	 *
	 * @param options
	 */
	GetContentCMS.prototype.updateOptions = function ( options ) {
		_.extendOwn( this.options, options || {} );
		_.extendOwn( this.request, options || {} );
	};


	/**
	 *
	 * @private
	 */
	GetContentCMS.prototype.__setEventHandlers = function () {
		this.$filterContainer.on( 'zg.filter.applyFilters', (function ( e, appliedFilters ) {
			this.processAppliedFilters( appliedFilters );
			this.getInfo();
		}).bind( this ) );

		this.$element.on( 'zg.getContentCMS.updateOptions', (function ( e, options ) {
			this.updateOptions( options );
			this.getInfo();
		}).bind( this ) );
	};


	// GETPRODUCT PLUGIN DEFINITION
	// ============================

	function Plugin ( option, updateOptions ) {
		return this.each( function () {
			var $this   = $( this );
			var data    = $this.data( 'zg.getContentCMS' );
			var options = $.extend( {}, root.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

			if ( !data ) {
				$this.data( 'zg.getContentCMS', (data = new GetContentCMS( this, options )) );
			} else if ( updateOptions && typeof option === 'object' ) {
				data.updateOptions( option );
			}

			data.getInfo();
		} );
	}

	$.fn.getContentCMS             = Plugin;
	$.fn.getContentCMS.Constructor = GetContentCMS;


	// GETPRODUCT DATA-API
	// ===================

	// default product - called on page load
	$( function () {
		$( SELECTOR ).each( function () {
			Plugin.call( $( this ) );
		} );
	} );

}.call( this, jQuery, _ ));
