// -----------------------------------------------------------------------------------------------------------------
// add your site specific javascript here
(function ( $ ) {
	'use strict';

	//
	// Piwik Pro
	// ------------------------------

	// Added to Cart
	jQuery(document).on('zg.product.addedToCart',function(event, status, product, data) {
		if(product){
			if( typeof product.categories !== 'undefined'){
			if (window._paq) _paq.push(['addEcommerceItem', product.code, product.name, product.categories[window.ACTUAL_CATEGORY].name, product.price.sell, data.quantity]); 
			}
		}
	});

	//
	// Increasing-decreasing qty input
	// ------------------------------

	$( '[data-zg-role="change-qty"]' ).click( function(event) {
		event.preventDefault();
		var type = $( this ).data( 'type' );
		var $inputQtySibling = $( this ).parent().siblings( '[data-zg-role="quantity"]' );
		var maxQty = $inputQtySibling.data( 'max' );
		var floatQty = $inputQtySibling.data( 'floatQty' );
		var qty = $inputQtySibling.val();
		var step;

		if( floatQty ){
				step = 0.01;
		}
		else {
				step = 1;
		}
		qty = Number(qty);
		
		$( '[data-zg-role="change-qty"]' ).attr( 'disabled', false );
		if( type == "minus" ){
			//If qty is 0, we will disable the current minus icon. 
			//The next time this function will be run, all the change qty icons will be enabled again.
			if( qty == 0 ){
				$( this ).attr( 'disabled', true );
			}
			else{
				qty = qty - step;
			}
		}
		else {
			//If qty is equal to maxQty, we will disable the current plus icon. 
			//The next time this function will be run, all the change qty icons will be enabled again.
			if( qty == maxQty ){
					$( this ).attr( 'disabled', true );
			}
			else {
				qty = qty + step;
			}
		}

		$( this ).parent().siblings( '[data-zg-role="quantity"]' ).val( qty );
	});
	
	//
	// Product dotdotdot
	// ------------------------------
	
	/*$(document).on('zg.getProductInfo.productCreated', function(e, element, options, product) {
		$('h3.name', element).dotdotdot({
			watch: true
		});
	});*/
	
	//
	// Options hider
	// ------------------------------

	$(document).on('handlebars-rendered-product-options', function() {
		$('.option .product-filters:empty').each(function() {
			$(this).closest('.option').hide();
		});
		$('.option-dropdown .form-control').each(function() {
			if ( $('option', this).length > 2 ) $(this).closest('.option-dropdown').show();
		});
	});
	
	// -----------------------------------------------------------------------------------------------------------------
	// Anything to be initialized on document ready goes here
	$( function () {

		setTimeout(function() {
			var delivery = Cookies.get("delivery");
			var $shipping_method = $('[data-zg-role="select-shipping-method"]');
			if ($shipping_method.length && delivery === undefined) {
				//Cookies.set("delivery", "1", { "path": "/", "domain": "www.butlerschocolates.com" });
				Cookies.set("delivery", "1", { "path": "/" });
				$shipping_method.find('.radio:first-child input').click();
			}
		}, 500);
		
		/*jQuery.validator.addMethod("telephone", function(value, element, params) {
  		return this.optional( element ) || /^\+[0-9]+\s/.test( value );
		}, 'Please enter country code prefixed with "+" to your telephone number');
		
		$('[name="shipping_address[telephone]"], #telephone').rules( "add", {
			telephone: true
		});*/
		
		$('#ccNumber').keyup(function() {
			var spaces = [4,8,12,16];
			var val = $(this).val();         
			var newval = '';         
			val = val.replace(/\s/g, ''); 
			for(var i = 0; i < val.length; i++) {             
				if(spaces.includes(i) && i > 0) newval = newval.concat(' ');             
				newval = newval.concat(val[i]);         
			}        
			$(this).val(newval); 
		});
		
		//
		// Delivery Calculator
		// ------------------------------
		
		$('[data-zg-role="delivery-calculator"]').change(function() {
			var zone_id = $(this).val();
			$('.delivery-calculator-zone').hide();
			$('.delivery-calculator-zone#zone-' + zone_id).show();
		});
		
		//
		// Gift Toggle
		// ------------------------------
		
		$('#gift_message').keyup(function() {
			if ( $(this).val() != "" ) $('#is_Gift').prop('checked', true);
			else $('#is_Gift').prop('checked', false);
		});
		
		//
		// Dreambag Toggle
		// ------------------------------
		
		$('[data-zg-role="toggle-dreambag"]').click(function(event) {
			event.preventDefault();
			$('[data-zg-role="dreambag-here"]').slideToggle();
		});
		
		//
		// Delivery
		// ------------------------------
		
		$('[href="#delivery"]').click(function(event) {
			event.preventDefault();
			if ( $('#delivery .accordion_body').is(':hidden') ) $('#delivery .accordion_head').click();
			var top = $('#delivery').offset().top - 104;
			$('html, body').animate({scrollTop: top});
		});
		
		//
		// Sorter
		// ------------------------------
		
		$('.tools-sorter ul').click(function(event){
			if ( !$(this).hasClass('active') ) {
				event.preventDefault();
				event.stopPropagation();
				$(this).addClass('active');
			}
		});
		$('body').click(function(event){
			$('.tools-sorter ul').removeClass('active');
		});
		
		//
		// Special Offers Carousel
		// ------------------------------
		
		if ( $('.component-product-marketing .product-list').length ){

			$('.component-product-marketing .product-list').slick({
				dots: false,
				infinite: false,
				arrows: true,
				slidesToShow: 4,
				slidesToScroll: 4,
				responsive: [
					{
						breakpoint: 1024,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 3
						}
					},
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 2
						}
					},
					{
						breakpoint: 480,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						}
					}
				]
			});
			$(document).on('zg.product.ready',function(){
				setTimeout(function(){
					$('.component-product-marketing .product-list').slick('refresh');
				},100);
			});

		};
		
		//
		// Slider Page Component
		// ------------------------------
		
		$('.component-slider').on('init', function(event, slick){
			$(this).show().next('.component-slider-loading').hide();
		}).slick({
			dots: true,
			infinite: true,
			arrows: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
  		autoplaySpeed: 5000
		});
		
		//
		// Sticky Menu
		// ------------------------------
		
		var maxTop = $('.header-above').outerHeight();
		function sticky() {
			var top = $(window).scrollTop();
			var width = $(window).width();
			var notification = $('body').hasClass('notification');
			if ( top >= maxTop ) $('#site_header_menu, body').addClass('sticky');
			else $('#site_header_menu, body').removeClass('sticky');
		}
		
		if ( !$('body').hasClass('checkout') ) {
			$(window).scroll(function() {
				maxTop = $('.header-above').outerHeight();
				sticky();
			});
			sticky();
		}
		
		//
		// Currency Dropdown
		// ------------------------------
		
		var country_code = $('[data-zg-role="language-currency"]').data('country-code');
		
		$('[data-zg-role="country"]').click(function(event) {
			var country = $(this).data('country');
			window.location.href = window.location.pathname + "?cCode=" + country;
			localStorage.country_selected = country;
		});
		
		$('.language-currency .dropdown').on('hide.bs.dropdown', function () {
			if ( country_code != 'IE' && country_code != 'GB' && country_code != 'US' && country_code != 'NZ' ) {
				window.location.href = window.location.pathname + "?cCode=IE";
				localStorage.country_selected = 'IE';
			}
		});
		
		if ( country_code != 'IE' && country_code != 'GB' && country_code != 'US' && country_code != 'NZ' ) {
			$('.language-currency .dropdown-toggle').dropdown('toggle');
		}
		
		//
		// Mobile menu toggle
		// ------------------------------
		
		$('.menu-dropdown .submenu .has-children a').hover(function(event){
			var id = $(this).data('id');
			var $subsubmenu = $('.menu-dropdown .subsubmenu[data-id="' + id + '"]');
			$(this).parent().siblings().find('a').removeClass('active');
			$(this).addClass('active');
			$subsubmenu.siblings().removeClass('active');
			$subsubmenu.addClass('active');
		}, function(event){
			// do nothing
		});
		
		//
		// Mobile menu toggle
		// ------------------------------
		
		$('.mobile-menu-toggle').click(function(event){
			event.preventDefault();
			$('html').toggleClass('mobile-menu-open');
		});
		
		//
		// Mobile menu accordtion
		// ------------------------------
		
		$('#site_mobile_menu .menu .has-children > a').click(function(event){
			event.preventDefault();
			$(this).parent().siblings().removeClass('active');
			$(this).parent().toggleClass('active');
		});
		
		//
		// Header Menu Touch
		// ------------------------------
		
		$('.touchevents #site_header .menu > li.has-children > a').click(function(event){
			event.preventDefault();
			event.stopPropagation();
			var isActive = $(this).parent().hasClass('active');
			$(this).parent().siblings().removeClass('active');
			if ( isActive ) {
				$(this).parent().removeClass('active');
				$('#site_dropdown_overlay').hide();
			} else {
				$(this).parent().addClass('active');
				$('#site_dropdown_overlay').show();
			}
		});
		$('.no-touchevents #site_header .menu > li.has-children').hover(function(event){
			$('#site_dropdown_overlay').show();
		},function(event){
			$('#site_dropdown_overlay').hide();
		});
		$('.touchevents body').click(function(event){
			$('#site_header .menu > li.has-children').removeClass('active');
			$('#site_dropdown_overlay').hide();
		});
		
		//
		// Header Messages Slick
		// ------------------------------
		var slick_settings = {
			dots: false,
			infinite: true,
			arrows: false,
			slidesToShow: 5,
			slidesToScroll: 1,
			prevArrow: '<button type="button" class="slick-arrow slick-prev"><i class="fa fa-chevron-left"></i></button>',
			nextArrow: '<button type="button" class="slick-arrow slick-next"><i class="fa fa-chevron-right"></i></button>',
			autoplay: true,
  		autoplaySpeed: 3000,
			responsive: [
				{
					breakpoint: 5000,
					settings: "unslick"
				},
				{
					breakpoint: 1500,
					settings: {
						arrows: true,
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		};
		
		var $slick = $('.header-messages .messages').slick(slick_settings);
		$(window).on('resize', function() {
   		if( $(window).width() < 1500 && !$slick.hasClass('slick-initialized')) {
				$('.header-messages .messages').slick(slick_settings);
			}
		});
		
	});

	// START: Shopbox integration
	$( function () {
		const getProducts = async ({typeOfRequest, currency, productName}) => {
			const SB_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTg1MjQ0NTAsImNsaWVudF9pZCI6IndDQ3I0elZFODh2ZEkyZHRlbWpJIiwic3ViIjoiZGUzNTE0MTNlZjYyNDQ5YWE0MWQ5NDNiNDI1ZGExZDAiLCJ1c2VyX2lkIjoiYjk1NDJkYWMwN2Q3NDA2OWJkMDQ2MzMwYTliZjM1NzIiLCJ3ZWJzaXRlIjoiIiwibmFtZSI6InRlc3QyIiwiYXBpIjp0cnVlLCJyZXBvcnRpbmciOnRydWV9.XakD9LQ_M9m59PtXmoaq5cG2yJg-7xXUypW2MqcVbwQ";
			
			let params = { 
				currency,
				fields: "id,price,url,imageUrl,name",
				sku: parseProductName(productName)
			}

			params.uuid = getShopboxUUID();

			if (typeof(window.getShopboxClientPageLoadID) !== 'undefined') {
				params.pageload_id = window.getShopboxClientPageLoadID();
			}

			const apiUrl = `https://api.shopbox.ai/api/v1/${typeOfRequest}?${new URLSearchParams(params).toString()}`;

			const response = await fetch(apiUrl, {
				method: "GET",
				headers: {
				"Content-Type": "application/json",
				"SB-TOKEN": SB_TOKEN,
				},
			});
			if (response.ok) {
				return await response.json();
			}
			throw new Error();
		};

		const sbProductsContainer = document.querySelectorAll( '[data-zg-role="sb-products-container"]' );
		for(const productsContainer of sbProductsContainer){
			getProducts(productsContainer.dataset).then( function(data) {
				const products = data.products || [];
				const productsPerItem = productsContainer.dataset.isFullWidth === 'yes' ? 4 : 3;
				const sizeItems = productsContainer.dataset.isFullWidth === 'yes' ? 3 : 4;
				let itemsRendered = '';
				
				for(let i=0; i < products.length; i++) {
					if(i !== 0 && i%productsPerItem == 0){
						let $carouselItem = document.createElement('div');
						$carouselItem.classList.add('item');

						if(i === productsPerItem){
							$carouselItem.classList.add('active');
						}

						$carouselItem.innerHTML = itemsRendered;
						productsContainer.appendChild($carouselItem);
						itemsRendered = '';
					}

					products[i].sizeItems = sizeItems;
					products[i].url = encodeURI(products[i].url);
					const previewProductRendered = handlebarsTemplates.render( "sb-preview-product", products[i]);
					itemsRendered += previewProductRendered;
				}

				if(itemsRendered.length > 0) {
					let $carouselItem = document.createElement('div');
					$carouselItem.classList.add('item');
					$carouselItem.innerHTML = itemsRendered;
					productsContainer.appendChild($carouselItem);
				}

				const btnsAddToCartByCode = productsContainer.querySelectorAll('[data-zg-role="add-to-cart-by-code"]');
				btnsAddToCartByCode.forEach(
					button => button.addEventListener("click", 
						() => addSBproductToCart({code: button.dataset.code}) 
					)
				);

				// Showing the carousel arrows if there is more than one slide to display
				if(products.length >= sizeItems){
					let carouselArrow = productsContainer.nextElementSibling;
					carouselArrow.classList.remove('hidden');
					carouselArrow = carouselArrow.nextElementSibling;
					carouselArrow.classList.remove('hidden');
				} else {
					//Setting .active to the first and only .item
					productsContainer.getElementsByClassName('item')[0].classList.add('active');
				}
				productsContainer.classList.remove('loading');
			});
		}
	});
})( jQuery );

// Function provided by Shopbox to generate a number to anonymously identify the user browsing the website.
function getShopboxUUID() {
	var su = localStorage.getItem("shopbox_uuid");
	if (su) return su;
	var jsb = localStorage.getItem("svelte-app-v0.1.304");
	if (jsb) {
		try {
		var psjb = JSON.parse(jsb);
		if (psjb.uuid) return psjb.uuid;
		} catch (e) {}
	}
	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
		v = c === "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

function addSBproductToCart({code}) {
	const data = {
		code,
		sku: 0,
		quantity: 1,
		isSubscription: false
	}

	const callback = {
		success: () => {
			$( document ).trigger( 'zg.product.addedToCart');
		}
	}

	// Add a product to the cart. On success, trigger an event to display the mini cart
	zgPost( 'addProductToCart', data, null, callback );
}

function parseProductName(productName) {
	return productName.replaceAll("[^a-zA-Z]+","").replaceAll(" ","").toLowerCase()
}
// END: Shopbox integration