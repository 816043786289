jQuery(document).ajaxSuccess(function(obj, data) {
//  console.log(data.getAllResponseHeaders());
        $(".cart_sections").show();
    if(data.getResponseHeader('Content-Type') == 'application/json'){
        var response = jQuery.parseJSON(data.responseText);
        for(key in response){
					
					//console.log('key = ' + key);
					
						if(key == 'products_in_cart'){
							
							var cart_total = 0;
							
							response[key].forEach(function(entry) {
								if(typeof(entry.total_price) !== 'undefined'){
                    cart_total = cart_total + parseFloat(entry.total_price);
                }	
								
								jQuery('.ajaxCartTotal').text(cart_total.toFixed(2));
							});
							
							 
							
							
						}

                    if(key == 'filters'){
                    setTimeout(function(){  
                                    var arr = new Array;
                                    var arra = new Array;
                                    $("#sl-filter-containers option").each( function(key, value) {
                                            var result = jQuery(this).val().split(',');
                                            arr.push ( result[0] );

                                    });
                                    for(i = 0; i < arr.length; i++) {
                                            if (arr[i] != arr[i+1]){
                                                    arra.push ( i );
                                            }
                                    }
                                    $("#sl-filter-containers option").each( function(key, value) {
                                            if(jQuery.inArray(key, arra) != -1){
                                                    // alert(key+''+arra);
                                                    jQuery(this).append( '</option><option>' );
                                            };
                                    });

                        }, 3000);
                    
                            }
                        }
                    }
});
//Content-Type

jQuery(document).ready(function($){

 $(document).on('zg.addressForm.ready', function(){
	// optionHtml = "";
	 
	/* for(key in countryObj){
		optionHtml += "<option value="+countryObj[key].id+">"+countryObj[key].country+"</option>";
	 }*/
	 
		//	$('[data-zg-role="country-id"]').html(optionHtml);
             $('.accountRegister').change(function() {
       countryAccLine = $( ".accountRegister" ).find( "option:selected" ).text();
      if(countryAccLine == 'Ireland'){
        $(".accPostCode").removeAttr("required");
        $(".accPostCodeRequired").hide();
      } 
      else{
        $(".accPostCodeRequired").show();
         $(".accPostCode").attr("required", "true");
      }     
    });
		});


         urlEndPoint = SGL_JS_CURRURL; 
     urlEndPoint = urlEndPoint.split('?');

    
      jQuery('#content_filter_rangeprice').rangeslider();

      jQuery('#content_filter_rangeprice').on( "slidestop", function( event, ui ) {
         window.location.href = SGL_JS_WEBROOT+urlEndPoint[0]+'?price-min='+jQuery('[name="range-min"]').val();
    } );
    
    jQuery('.minusminus').on('mouseup', function(){ 
        window.location.href = SGL_JS_WEBROOT+urlEndPoint[0]+'?price-min='+jQuery('[name="range-min"]').val();
    })

    jQuery('.minusminus').on('mousedown', function(){
         jQuery('[name="range-min"]').val(parseInt(jQuery('[name="range-min"]').val())-rangeConfig.step).change();
    })

    jQuery('.plusplus').on('mouseup', function(){
      window.location.href = SGL_JS_WEBROOT+urlEndPoint[0]+'?price-min='+jQuery('[name="range-min"]').val();
    })

    jQuery('.plusplus').on('mousedown', function(){
         jQuery('[name="range-min"]').val(parseInt(jQuery('[name="range-min"]').val())+rangeConfig.step).change();
    })

    priceMin = 0;
    for(key in ZG_CONFIG.initialUriComponents){
        if(key == 'price-min'){
            priceMin = ZG_CONFIG.initialUriComponents[key];
        }
    } 
    jQuery('[name="range-min"]').val(priceMin).change();
	
	if(priceMin == rangeConfig.min)
	{
		$(".sorryText").text("Drag the bar to find out");
	}
	else if(priceMin == rangeConfig.step)
	{
		$(".sorryText").text("Really, frightfully sorry");
	}	
	else if(priceMin == rangeConfig.max)
	{
		$(".sorryText").text("Extremely sorry");
	}	
	
    jQuery('[name="range-min"]').change(function(){
        return;
        jQuery('#filter_price').find('.ui-rangeslider-sliders').find('a').eq(0).css({border: '2px solid lime'});
        jQuery('[name="range-1a"]').val(jQuery(this).val()).change();
        jQuery('[data-role="rangeslider"]').rangeslider("refresh");
        jQuery('[data-role="rangeslider"]').trigger('slidechange').trigger('slidestop');
        jQuery('#filter_price').find('.ui-rangeslider-sliders').find('a').eq(0).trigger('vmousedown');
        jQuery('#filter_price').find('.ui-rangeslider-sliders').find('a').eq(0).trigger('keydown');
        return;
        //alert(jQuery('[name="range-1a"]').parent().length);
    /*  rangSlider.trigger('slidestart');
        rangSlider.trigger('slidestart');
        rangSlider.trigger('slidestart');*/
        jQuery('.ui-rangeslider-sliders').find('a').eq(0).mousedown();
        
        
        
        jQuery('[name="range-1a"]').parent().trigger('slidestart');
jQuery('[name="range-1a"]').parent().trigger('slidechange');
jQuery('[name="range-1a"]').parent().trigger('slidestop');
        
    });
		

	
    happinessCard();
    happinessCart();
    
    countryChange();
    
    $("#cart-items").hide();
    $("#cart-empty").hide();
    $("#cart-items-nav").hide();
    $("#cart-empty-nav").hide();
    

    jQuery("#gallery").unitegallery({
        tiles_type:"nested"
    });

    var items = jQuery(localStorage.getItem('popupTimeVal'));
    if (items === null || items.length === 0){
        $('#exitpopup').show();
    }else{
        localStorage.setItem('popupTimeVal','true');
        $('#exitpopup').hide();
    }


    //bxSliderlocator();
//add functionality here


    setTimeout(function(){
        instaSlider();
    }, 10000);

    nearest_store()
    cartItemUpdate();
    bxSlider();
    accordion();

// bxSliderInsta();
    /**header user-name split**/
    jQuery('.user-name').remove();
    var input = $('.user-id').text();
    var arr = input.split("@");
    var name = arr[0];
    jQuery('.user-action').append("<span class='user-name'>"+name+"</span>");
    /****/


    jQuery('.thumbnail img').on('click', function(){
        var dir = $(this).attr("src");
        var res = dir.replace("small", "big");
        $('#zoomMain').append("<img src="+res+" id='mychange'/>")
        $('#zoomMain img').hide();
        $('#mychange').show();
        $('#zoomMain img').attr("src", res);
    });


    jQuery(".cart_bucket").hover(function(){

            var myval=jQuery('#qty-view-range').text();
            if(myval=='0'){
                $("#cart-empty").show();
            }else{
                $("#cart-items").show();
            }
        },
        function () {
            var myval=jQuery('#qty-view-range').text();
            if(myval=='0'){
                $("#cart-empty").hide();
            }else{
                $("#cart-items").hide();
            }
        });




    jQuery(".cart_bucket_nav").hover(function(){

            var myval=jQuery('#qty-view-range').text();
            if(myval=='0'){
                $("#cart-empty-nav").show();
            }else{
                $("#cart-items-nav").show();
            }
        },
        function () {
            var myval=jQuery('#qty-view-range').text();
            if(myval=='0'){
                $("#cart-empty-nav").hide();
            }else{
                $("#cart-items-nav").hide();
            }
        });





    function showPop() {
        $("#cart-items").style.visibility = 'visible';
    }
    function hidePop() {
        $("#cart-items").style.visibility = 'hidden';
    }

    function CartHover() {
        var myval=$('.qty-view span').text();
        var cartItems = document.getElementById("cart-items");
        cartItems.style.visibility = 'hidden';
        var obj = document.getElementByClass("dropdown-toggle");
        obj.onmouseover = showPop;
        obj.onmouseout = hidePop;
    }


    /************/
    jQuery('.user-action').on('click', function(){
        jQuery('.user-drop-list').toggle();
    });
    $(".store_menulist > li:first").addClass("current");
    jQuery(".store_menulist a").click(function(event) {
        if(!$(this).hasClass('newOffering')){
            event.preventDefault();
        }


        $(this).parent().addClass("current");
        $(this).parent().siblings().removeClass("current");
        var tab = $(this).attr("href");
        $(".opening_time_desc").not(tab).css("display", "none");
        $(tab).fadeIn();
    });


    jQuery('.user-action').on('change',function(){
        jQuery('.user-drop-list').hide();
    });

});

function bxSlider(){
    $('.bxslider').bxSlider({
        infiniteLoop: false,
        hideControlOnEnd: true,
        auto: true,
        speed:500
    });
}


function instaSlider() {
    $('.instagram__slider').flickity({
        pageDots: false,
        arrowShape: {
            x0: 10,
            x1: 40, y1: 50,
            x2: 45, y2: 50,
            x3: 15
        },
        initialIndex: 3
    })
}

function cartItemUpdate(){
    var cartPrice = jQuery('.ajaxCartTotal').text();
    // alert(cartPrice);
    //$('.total-value').text(cartPrice);
}

function PDP(){
    $("thumbnail").click(function(){
        $(this).toggleClass("active");
    });
}

function cartBar(){
    var Qty = $(".qty-view").text();
    if(Qty == 0){
        $("#breadcrumb-payment").css("display","none");
    }
}
if($(window).width() <= 768){
    function bxSliderlocator(){
        $('.bxsliderlocator').bxSlider({
            auto: false,
            stopAutoOnClick: true,
            pager: false,
            slideWidth: 5000,
            controls:false
        });
    }
}

function nearest_store(){
    $("nearest_search").hide();
}

function accordion(){
    //toggle the component with class accordion_body
    $(".accordion_head").click(function() {
        if ($('.accordion_body').is(':visible')) {
            $(".accordion_body").slideUp(300);
            $(".plusminus").text('+');
        }
        if ($(this).next(".accordion_body").is(':visible')) {
            $(this).next(".accordion_body").slideUp(300);
            $(this).children(".plusminus").text('+');
        } else {
            $(this).next(".accordion_body").slideDown(300);
            $(this).children(".plusminus").text('-');
        }
    });
}


function happinessCard(){
    $("#happinessForm").submit(function(){
        $("#happinessForm").hide();
    });
}

function happinessCart(){
    $("#card_fidelity").submit(function(){
        $("#card_fidelity").hide();
    });
}



 // function rangSlider() {
 //    $( "#slider" ).slider();
 //  }

// function rangSlider() {
//     $( "#slider-range" ).slider({
//       range: true,
//       min: 0,
//       max: 500,
//       values: 0,
//       slide: function( event, ui ) {
//        // $( "#amount" ).val( "$+ ui" .values[ 0 ] + " - $" + ui.values[ 1 ] );
//       }
//     });
//     /*$( "#amount" ).val( "$" + $( "#slider-range" ).slider( "values", 0 ) +
//       " - $" + $( "#slider-range" ).slider( "values", 1 ) );*/
// } 

function countryChange(){
    $('.countryDropDown').change(function() {
       countryNewLine = $( ".countryDropDown" ).find( "option:selected" ).text();
      if(countryNewLine == 'Ireland'){
        $(".postalCode").removeAttr("required");
        $(".postalcode_require").hide();
      } 
      else{
        $(".postalcode_require").show();
         $(".postalCode").attr("required", "true");
      }     
    });

     
};


$(document).ready(function(){
    $("#Guest_tab").hide();
    $("#Login_tab").hide();
    $("#Register_tab").hide();
    $(".guest-tab").click(function(){ 
        $('.choice_test').hide();
        $("#Guest_tab").show();
        $("#Login_tab").hide();
        $("#Register_tab").hide();
    });
    $(".login-tab").click(function(){
				$('.choice_test').hide();
        $(this).css('background', '#DBB364')
        $("#Guest_tab").hide();
        $("#Login_tab").show();
        $("#Register_tab").hide();
    });
    $(".register-tab").click(function(){
				$('.choice_test').hide();
        $(this).css('background', '#DBB364')
        $("#Guest_tab").hide();
        $("#Login_tab").hide();
        $("#Register_tab").show();
    });
});
