(function($){

	$(document).ready(function(){
		var cookiesNoticeHidden = Cookies.get('cookies-notice-hidden');

		if(cookiesNoticeHidden === '1') return;

		$('.cookies-bar-hide').on('click', function() {
			Cookies.set('cookies-notice-hidden', '1');
			$('.cookies-bar').slideUp();
		});

		$('.cookies-bar').show();
	});

})(jQuery)