(function($){

	$(document).ready(init);

	function init() {
		$('.thumb-btns').flickity({
			watchCSS: true,
			initialIndex: 1,
			imagesLoaded: true,
			freeScroll: true,
			wrapAround: false,
			prevNextButtons: false,
			pageDots: false
		});
	}

})(jQuery);