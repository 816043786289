/**
 *
 * exportMissingGeolocation
 *
 *    Create a table with all the stores with missing geolocation.
 *    Creates a csv file to be imported in the CMS.
 *
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 */

(function () { /* global _, DEBUG, isGoogleMapsAvailable, google */
	'use strict';

	var root = this;


	// CLASS DEFINITION
	// ================

	var CenterMap = function ( storeLocator ) {
		this.storeLocator = storeLocator;

		if ( !isGoogleMapsAvailable() ) {
			throw new Error( 'StoreLocator.CenterMap - FAILED - Google maps is not available' );
		}

		this.geocoder = new google.maps.Geocoder();
	};


	/**
	 *
	 * @param {string}  address
	 * @param {number=} zoomIn
	 */
	CenterMap.prototype.byAddress = function ( address, zoomIn ) {
		if ( _.isString( address ) && this.storeLocator.map ) {
			this.geocoder.geocode( {'address': address}, (function ( results, status ) {
				if ( status === google.maps.GeocoderStatus.OK ) {
					this.byLocationObject( results[0].geometry.location );

					if ( _.isNumber( zoomIn ) && this.storeLocator.map.getZoom() < zoomIn ) {
						this.storeLocator.map.setZoom( zoomIn );
					}
				}
			}).bind( this ) );
		} else if ( DEBUG ) {
			console.warn( 'StoreLocator.centerMap.byAddress - FAILED' );
		}
	};


	/**
	 *
	 * @param {string|number} lat
	 * @param {string|number} lng
	 * @param {number=}       zoomIn
	 */
	CenterMap.prototype.byCoordinates = function ( lat, lng, zoomIn ) {
		var location;

		if ( this.storeLocator.map ) {
			location = new google.maps.LatLng( lat || 0, lng || 0 );

			// set the coordinates
			if ( location ) {
				this.storeLocator.map.setCenter( location );
			}

			if ( _.isNumber( zoomIn ) && this.storeLocator.map.getZoom() < zoomIn ) {
				this.storeLocator.map.setZoom( zoomIn );
			}
		} else if ( DEBUG ) {
			console.warn( 'StoreLocator.centerMap.byCoordinates - FAILED' );
		}
	};


	/**
	 *
	 * @param {Object}  location
	 * @param {number=} zoomIn
	 */
	CenterMap.prototype.byLocationObject = function ( location, zoomIn ) {
		if ( _.isObject( location ) && this.storeLocator.map ) {
			this.storeLocator.map.setCenter( location );

			if ( _.isNumber( zoomIn ) && this.storeLocator.map.getZoom() < zoomIn ) {
				this.storeLocator.map.setZoom( zoomIn );
			}
		} else if ( DEBUG ) {
			console.warn( 'StoreLocator.centerMap.byLocationObject - FAILED' );
		}
	};

	/**
	 *
	 *
	 */
	CenterMap.prototype.onDefaultLocation = function () {
		if ( this.storeLocator.map ) {
			// Try to center the map default (current?) country
			if ( this.storeLocator.options.defaultCountry ) {
				this.byAddress( this.storeLocator.options.defaultCountry );
			}

			// center the map in a specific store.
			// useful for the single store pages.
			if (
				this.storeLocator.options.defaultStore &&
				this.storeLocator.options.stores &&
				this.storeLocator.options.stores[this.storeLocator.options.defaultStore]
			) {
				this.onStore( this.storeLocator.options.defaultStore );
			}

			// Try to center the map in the user location
			if ( this.storeLocator.options.centerOnUser ) {
				this.onUser();
			}
		} else if ( DEBUG ) {
			console.warn( 'StoreLocator.centerMap.onDefaultLocation - FAILED' );
		}
	};


	/**
	 *
	 * @param {string} storeId
	 */
	CenterMap.prototype.onStore = function ( storeId ) {
		if ( storeId && this.storeLocator.options.stores && this.storeLocator.options.stores[storeId] ) {
			if ( this.storeLocator.mapMarkersMgr.mapMarkers[storeId] ) {
				// Get position form the marker if it exists
				this.byLocationObject(
					this.storeLocator.mapMarkersMgr.mapMarkers[storeId].getPosition(),
					this.storeLocator.options.storeZoomLevel
				);
			} else if (
				!isNaN( this.storeLocator.options.stores[storeId].fields.Latitude ) &&
				!isNaN( this.storeLocator.options.stores[storeId].fields.Longitude )
			) {
				// Center the map in the store location.
				this.byCoordinates(
					+this.storeLocator.options.stores[storeId].fields.Latitude,
					+this.storeLocator.options.stores[storeId].fields.Longitude,
					this.storeLocator.options.storeZoomLevel // zoomIn
				);
			} else {

				this.storeLocator.requestStoreLocation(
					storeId,
					0,
					( function ( storeId ) {
						this.onStore( storeId );
					}).bind( this, storeId )
				);
			}
		}
	};


	/**
	 * Use W3C Geolocation API to center position on the user
	 *
	 */
	CenterMap.prototype.onUser = function () {
		// W3C Geolocation
		if ( 'geolocation' in navigator ) {
			navigator.geolocation.getCurrentPosition(
				// success
				(function ( position ) {
					if ( position && position.coords ) {
						this.byCoordinates( position.coords.latitude, position.coords.longitude, this.storeLocator.options.storeZoomLevel );
					}
				}).bind( this ),

				// error
				function ( error ) {
					if ( DEBUG ) {
						console.log( 'CenterMap.onUser - FAILED', error );
					}
				}
			);
		}
	};


	// -----------------------------------------------------------------------------------------------------------------

	root.ZgStoreLocatorCenterMap = CenterMap;

}.call( this ));
