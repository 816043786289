/**
 * Plus minus controls
 *
 * @author: Michał Skrzypek <michal[dot]skrzypek[at]studioworx[dot]co[dot]uk>
 */

(function ( $, _ ) { /* global _, JS_TRANSLATIONS, DEBUG, handlebarsTemplates */
	'use strict';

	/**
	 * @selector data-zg-role="plus-minus" The plugin start for each selector in the dom when the page load
	 */

	var selector = '[data-zg-role="plus-minus"]';

	// RETURN CLASS DEFINITION
	// =======================

	var PlusMinus = function ( element, options ) {
		this.$element = $(element);
		this.options = options;
		this._initialize();
	};

	/**
	 * @param {string} valueContainer container to fill in leftover value
	 * @param {string} totalContainer container containing total value
	 */

	PlusMinus.DEFAULTS = {
		plus: '[data-zg-role="plus"]',
		minus: '[data-zg-role="minus"]',
		qty: '[data-zg-role="quantity"]'
	};

	/**
	 * _initialize
	 */
	PlusMinus.prototype._initialize = function () {
		var self = this;
		
		$(this.options.plus, this.$element).click(function() {
			var qty = $(self.options.qty, self.$element).val();
			var max = $(self.options.qty, self.$element).attr('max');
			qty = parseInt(qty);
			qty = qty + 1;
			if ( qty > max ) qty = max;
			$(self.options.qty, self.$element).val(qty);

		});
		$(this.options.minus, this.$element).click(function() {
			var qty = $(self.options.qty, self.$element).val();
			var min = $(self.options.qty, self.$element).attr('min');
			qty = parseInt(qty);
			qty = qty - 1;
			if ( qty < min ) qty = min;
			$(self.options.qty, self.$element).val(qty);
		});
	};

	// RETURN PLUGIN DEFINITION
	// ========================

	function Plugin ( option ) {
		return this.each( function () {
			var $this = $( this );
			var data = $this.data( 'zg.plus-minus' );
			var options = $.extend( {}, PlusMinus.DEFAULTS, window.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

			if ( !data ) {
				$this.data( 'zg.spend-more', new PlusMinus( this, options ) );
			}
		} );
	}

	$.fn.zgPlusMinus = Plugin;
	$.fn.zgPlusMinus.Constructor = PlusMinus;

	// RETURN DATA-API
	// ===============
	
	/**
	 * @method document
	 * @listen document#zg.product.ready
	 */
	$( document ).on( 'zg.product.ready', function ( e, element ) {
		if ( $('.quick-view' ).length > 0 ){
			if ( element.$element.find( selector ).length ) {
				Plugin.call( element.$element );
			}
		}
	} );

	$( function () {
		$( selector ).each( function () {
			Plugin.call( $( this ) );
		} );
	} );

}( jQuery, _ ));
