/**
 *
 * Get list of UPS Access Point for a specific Location
 *
 * @author David Pocina  <dpocina[at]kooomo[dot]com>
 *
 */

/* global _, JS_TRANSLATIONS, handlebarsTemplates, zgPost */

(function ( $, _ ) {
	'use strict';

	// Establish the root object ('window' in the browser)
	var root = this;

	/**
	 * @selector data-zg-role="get-ups-access-points" The plugin start if there is the selector in the dom when the page load
	 */
	var SELECTOR = '[data-zg-role="get-collection-points"]';

	/**
	 * @param {number} [currentAddressType]             Current Address type
	 * @param {string} [elementCmsContainer]
	 * @param {string} [elementFilterContainer]
	 * @param {string} [elementPaginationContainer]
	 * @param {string} [elementPaymentsContainer]       Selector of content of payments method
	 * @param {string} [elementSelectedStoreContainer]
	 * @param {string} [elementShippingWarning]         Warning container if shipping address is not valid
	 * @param {string} [elementStoreLocatorContainer]
	 * @param {string} [elementStoreSelectorButton]     Button for change store (if you already select a store to pickup in store)
	 * @param {string} [elementUserAddress]             Selector of shipping and billing address in confirm page
	 * @param {string} [elementUserAddressForm]
	 * @param {string} [elementUserAddressUserEmail]
	 * @param {string} [elementUserAddressUserName]
	 * @param {string} [elementUserAddressUserSurname]
	 * @param {string} [templateSelectedStore]
	 */
	var DEFAULTS = {
		currentAddressType:            null,
		elementCmsContainer:           '[data-zg-role="get-content-cms"]',
		elementFilterContainer:        '[data-zg-role="filters"]',
		elementPaginationContainer:    '[data-zg-role="pagination"]',
		elementPaymentsContainer:      '[data-zg-role="payments"]',
		elementModalSelectStore:       '#collectionPointsModal',
		elementSelectedStoreContainer: '[data-zg-role="pickUp-selectedStore"]',
		elementShippingWarning:        '[data-zg-role="shipping-method-warning"]',
		elementStoreLocatorContainer:  '[data-zg-role="store-locator"]',
		elementStoreSelectorButton:    '[data-zg-role="pickUp-show-store-selector"]',
		elementUserAddress:            '[data-zg-role="address"]',
		elementUserAddressForm:        '[data-zg-role="pickUp-form"]',
		elementUserAddressUserEmail:   '[data-zg-role="pickUp-email"]',
		elementUserAddressUserName:    '[data-zg-role="pickUp-name"]',
		elementUserAddressUserSurname: '[data-zg-role="pickUp-surname"]',
		templateSelectedStore:         'pickUpStore-selectedStore'
	};


	// GETUPSACCESSPOINTS CLASS DEFINITION
	// ===================================

	/**
	 * @param {HTMLElement} element
	 * @param {!Object}     options
	 *
	 * @constructor
	 */
	var GetCollectionPoints = function ( element, options ) {
		this.$element = $( element );
		this.options  = options;

		this.shippingInfo = null;
		this.position     = null;

		this.requestUrl    = root.makeUrl( {module: 'eshop', manager: 'eshop', action: 'getCollectionPoints'} );
		this.latestRequest = null;

		this.selectedStore = null;

		this.$selectedStoreContainer = $( this.options.elementSelectedStoreContainer );

		this.$modalSelectStore = $( this.options.elementModalSelectStore );

		this.$userAddress      = $( this.options.elementUserAddress );
		this.$paymentContainer = $( this.options.elementPaymentsContainer );
		this.$shippingWarning  = $( this.options.elementShippingWarning );

		this.$cmsContainer          = $( this.options.elementCmsContainer );
		this.$filterContainer       = $( this.options.elementFilterContainer );
		this.$paginationContainer   = $( this.options.elementPaginationContainer );
		this.$storeLocatorContainer = $( this.options.elementStoreLocatorContainer );

		this.$pickUpForm = $( this.options.elementUserAddressForm );

		this.__setEventHandlers();
	};


	/**
	 * try to request the collection points based on the current shipping method and location
	 *
	 */
	GetCollectionPoints.prototype.requestPointsFromLocation = function () {
		this.__emptyItemsList();

		if ( !this.shippingInfo || !this.shippingInfo.courierParameters ) {
			return;
		}

		if ( String( this.shippingInfo.courierParameters.isPickUpInStore ) === '1' ) {
			this.__requestPickUpInStore();
		} else if ( String( this.shippingInfo.courierParameters.hasCollectionPoints ) === '1' ) {
			this.__requestAccessPoints();
		}
	};


	/**
	 * Render the current selected store or a warning if none is selected
	 *
	 */
	GetCollectionPoints.prototype.renderSelectedStore = function () {
		this.$selectedStoreContainer.html(
			handlebarsTemplates.render( this.options.templateSelectedStore, this.selectedStore )
		);
	};


	/**
	 *
	 * @param [position]
	 */
	GetCollectionPoints.prototype.setPosition = function ( position ) {
		this.position = position || null;

		this.requestPointsFromLocation();
	};


	/**
	 *
	 * @param [storeInfo]
	 */
	GetCollectionPoints.prototype.setSelectedStore = function ( storeInfo ) {
		this.selectedStore = storeInfo || null;

		this.$pickUpForm.find( '[type="submit"]' ).prop( 'disabled', !(this.selectedStore) );

		this.renderSelectedStore();

	};


	/**
	 *
	 * @param shippingInfo
	 */
	GetCollectionPoints.prototype.setShippingInformation = function ( shippingInfo ) {
		this.shippingInfo = null;
		if ( shippingInfo && shippingInfo.courierParameters ) {
			this.shippingInfo = shippingInfo;

			this.__updateShippingLayout();
		} else {
			throw new Error( 'GetCollectionPoints.setShippingInformation - missing shipping information' );
		}

		this.requestPointsFromLocation();
	};


	// PRIVATE METHODS
	// ------------------------------------------------------------------------


	/**
	 *
	 * @returns {{
	 *   name: string,
	 *   surname: string,
	 *   email: string,
	 *   courier_id: (number|string),
	 *   store_id: (number|string),
	 *   country_code: string,
	 *   state_code: string,
	 *   addr_1: string,
	 *   addr_2: string,
	 *   city: string,
	 *   phone: (number|string),
	 *   post_code: (number|string)
	 * }}
	 *
	 * @private
	 */
	GetCollectionPoints.prototype.__generateDeliveryAddressData = function () {
		return {
			// we assume the validation is being taken care of by the form itself
			name:    $( this.options.elementUserAddressUserName ).val(),
			surname: $( this.options.elementUserAddressUserSurname ).val(),
			email:   $( this.options.elementUserAddressUserEmail ).val(),

			// courier information
			courier_id: this.shippingInfo.courierId,

			// store address
			store_id:     this.selectedStore.fields.StoreId || this.selectedStore.id || 'unknown',
			country_code: this.selectedStore.fields.CountryCode,
			state_code:   this.selectedStore.fields.ProvinceCode || this.__getRegionCodeFromPosition(),
			state_id:     this.selectedStore.fields.ProvinceId,
			addr_1:       this.selectedStore.fields.Street,
			addr_2:       this.selectedStore.fields.Name,
			city:         this.selectedStore.fields.City,
			phone:        this.selectedStore.fields.Phone,
			post_code:    this.selectedStore.fields.ZipCode
		};
	};


	/**
	 *
	 * @param {string} type
	 * @returns {string|undefined}
	 * @private
	 */
	GetCollectionPoints.prototype.__getAddressComponentFromPosition = function ( type ) {
		var addressComponent;

		if ( this.position ) {
			_.find( this.position.address_components, function ( component ) {
				var found = component.types && component.types[0] === type;

				if ( found ) {
					addressComponent = component.short_name;
				}

				return found;
			} );
		}

		return addressComponent;
	};


	/**
	 *
	 * @returns {string|undefined}
	 * @private
	 */
	GetCollectionPoints.prototype.__getCountryCodeFromPosition = function () {
		return this.__getAddressComponentFromPosition( 'country' );
	};


	/**
	 *
	 * @returns {string|undefined}
	 * @private
	 */
	GetCollectionPoints.prototype.__getRegionCodeFromPosition = function () {
		return this.__getAddressComponentFromPosition( 'administrative_area_level_2' );
	};


	/**
	 *
	 * @private
	 */
	GetCollectionPoints.prototype.__emptyItemsList = function () {
		var cmsPlugin          = this.$cmsContainer.data( 'zg.getContentCMS' );
		var filterPlugin       = this.$filterContainer.data( 'zg.filters' );
		var storeLocatorPlugin = this.$storeLocatorContainer.data( 'zg.storeLocator' );

		// hide containers
		this.$filterContainer.addClass( 'hidden' );
		this.$paginationContainer.addClass( 'hidden' );

		// stop CMS requests (pickUpInStore)
		if ( cmsPlugin ) {
			cmsPlugin.updateOptions( {type: null} );
		}

		// reset filters
		if ( filterPlugin ) {
			filterPlugin.setItems( [] );
			filterPlugin.resetFilters();
		}

		// empty stores list
		if ( storeLocatorPlugin ) {
			storeLocatorPlugin.parseStores( null );
		}
	};


	/**
	 *
	 * @private
	 */
	GetCollectionPoints.prototype.__onSavedAddress = function () {
		location.replace( root.makeUrl( {module: 'eshop', manager: 'confirm', action: 'list'} ) );
	};


	/**
	 *
	 * @private
	 */
	GetCollectionPoints.prototype.__requestAccessPoints = function () {
		var cCode;

		if ( this.latestRequest ) {
			this.latestRequest.abort();
		}

		if ( this.position ) {
			cCode = this.__getCountryCodeFromPosition();

			if ( cCode ) {
				this.latestRequest = $.ajax( {
					type:     'POST',
					url:      this.requestUrl,
					dataType: 'json',

					data: {
						courier_id:   this.shippingInfo.courierId,
						country_code: cCode,
						latitude:     this.position.geometry.location.lat(),
						longitude:    this.position.geometry.location.lng(),
						address:      this.position.formatted_address
					},

					success: function ( response ) {
						var storeLocatorPlugin = this.$storeLocatorContainer.data( 'zg.storeLocator' );
						if ( storeLocatorPlugin ) {
							storeLocatorPlugin.parseStores( response.accessPoints || [] );
						}

						this.$paginationContainer
							.removeClass( 'hidden' )
							.zg_pagination( this.options, response.accessPoints || [] );

						if ( !response.accessPoints || response.accessPoints.length === 0 ) {
							this.__requestAccessPointsError( response );
						}
					}.bind( this ),

					error: function ( response ) {
						this.__requestAccessPointsError( response || {} );
					}.bind( this )
				} );
			}
		}
	};


	/**
	 *
	 * @param {Object} response
	 * @private
	 */
	GetCollectionPoints.prototype.__requestAccessPointsError = function ( response ) {
		if ( root.DEBUG ) {
			console.warn( 'GetCollectionPoints', response );
		}

		$( document ).trigger( 'zg-error', [{
			message: response.message || root.JS_TRANSLATIONS.genericErrorMsg
		}] );
	};


	/**
	 * The selected shipping method is a pickUpInStore
	 * Request info from the CMS stores
	 *
	 * @private
	 */
	GetCollectionPoints.prototype.__requestPickUpInStore = function () {
		var cmsPlugin = this.$cmsContainer.data( 'zg.getContentCMS' );
		if ( cmsPlugin ) {
			cmsPlugin.updateOptions( {type: 'Stores'} );
			cmsPlugin.getInfo();
		}

		this.$paginationContainer.removeClass( 'hidden' );
		this.$filterContainer.removeClass( 'hidden' );
	};


	/**
	 *
	 * @private
	 */
	GetCollectionPoints.prototype.__saveDeliveryAddress = function () {
		var request;

		if ( this.selectedStore ) {
			request = this.__generateDeliveryAddressData();

			if ( request ) {
				zgPost(
					'pickupInStore',
					request,
					null,
					{
						success: (this.__onSavedAddress).bind( this )
					}
				);
			}
		} else {
			$( document ).trigger( 'zg-error', [{
				message: JS_TRANSLATIONS['pickUpInStore.pleaseSelectAStore'] || 'Please select a store'
			}] );
		}
	};


	/**
	 *
	 * @param selector
	 * @private
	 */
	GetCollectionPoints.prototype.__showAddressSelector = function ( selector ) {
		// hide address and payment
		this.$userAddress.addClass( 'hidden' );
		this.$paymentContainer.addClass( 'hidden' );
		this.$shippingWarning.addClass( 'hidden' );

		// show the selector
		if ( selector ) {
			this.$shippingWarning
				.filter( '[data-type="' + selector + '"]' )
				.removeClass( 'hidden' );
		}

		if ( selector === 'pickUpInStore' && this.selectedStore && _.isEmpty(this.selectedStore) ) {
			// The warning is for pickUpInStore and the selectedStore is initialised (is not the first load) but is
			// empty (no collection point has been selected yet)
			this.$modalSelectStore.modal( 'show' );
		}
	};


	/**
	 *
	 * @private
	 */
	GetCollectionPoints.prototype.__updateShippingLayout = function () {
		if ( String( this.shippingInfo.type ) === String( this.options.currentAddressType ) ) {
			this.$shippingWarning.addClass( 'hidden' );
			this.$userAddress.removeClass( 'hidden' );
			this.$paymentContainer.removeClass( 'hidden' );
		} else {
			// the address and method are different
			this.__showAddressSelector( String( this.shippingInfo.type ) === '0' ? 'standard' : 'pickUpInStore' );
		}
	};


	// EVENT HANDLERS
	// ------------------------------------------------------------------------


	/**
	 *
	 * @private
	 */
	GetCollectionPoints.prototype.__setEventHandlers = function () {
		$( document ).on( 'zg.storeLocator.userMarker', (function ( e, position ) {
			this.setPosition( position );
		}).bind( this ) );

		$( document ).on( 'zg.selectShipping.shippingReady', (function ( e, shippingId, shippingInfo ) {
			this.setShippingInformation( shippingInfo );
		}).bind( this ) );

		//
		$( document ).on( 'click.zg.getCollectionPoints', this.options.elementStoreSelectorButton, (function ( e ) {
			e.preventDefault();

			this.__showAddressSelector( 'pickUpInStore' );
		}).bind( this ) );

		// If store was selected by marker, set selected store info
		$( document ).on( 'zg.storeLocator.selectedMarker', (function ( e, storeId, storeInfo ) {
			this.setSelectedStore( storeInfo );
		}).bind( this ) );

		this.$pickUpForm.on( 'submit.zg.pickUpInStore', (function ( e ) {
			e.preventDefault();

			if ( this.$pickUpForm.data( 'zgValidator' ).validate() ) {
				this.__saveDeliveryAddress();
			}
		}).bind( this ) );

		this.$modalSelectStore.on( 'shown.bs.modal.zg.getCollectionPoints', function () {
			$( document ).trigger( 'zg.storeLocator.initMap' );
		} );
	};


	// GETUPSACCESSPOINTS PLUGIN DEFINITION
	// ====================================

	function Plugin ( option ) {
		return this.each( function () {
			var $this   = $( this );
			var data    = $this.data( 'zg.getCollectionPoints' );
			var options = $.extend( {}, root.ZG_CONFIG || {}, DEFAULTS, $this.data(), typeof option === 'object' && option );

			if ( !data ) {
				$this.data( 'zg.getCollectionPoints', (data = new GetCollectionPoints( this, options )) );
			}

			data.renderSelectedStore();
		} );
	}

	$.fn.getCollectionPoints             = Plugin;
	$.fn.getCollectionPoints.Constructor = GetCollectionPoints;


	// GETUPSACCESSPOINTS DATA-API
	// ===========================

	// default product - called on page load
	$( function () {
		$( SELECTOR ).each( function () {
			Plugin.call( $( this ) );
		} );
	} );

}.call( this, jQuery, _ ));
