/**
 *
 * Plugin for change lang links. When the user click on lang link, the javascript create a form with a input name lang selected lang and submit the form
 *
 * @author NoName
 */

/**
 * @event selector#click.zg.changeLanguage Create the form with lang selected and submit for change lang
 * @type {string} languageLinks Code of lang selected
 */

(function($) {
    'use strict';

    $(function() {

        /**
         * @selector data-zg-role="zg-language" The plugin start on click of selector
         */
        var selector = '[data-zg-role="zg-language"]';
        var languageLinks = '[data-lang]';

        /**
         *
         * @param {string} lang
         */
        function changeLanguage( lang ) {
            var $formDOM;

            if ( lang ) {
                $formDOM = $('<form></form>')
                    .attr('method', 'POST')
                    .append(
                        $('<input />')
                            .attr('name', 'lang')
                            .val( lang )
                    );

                $formDOM.appendTo( $('body') ).submit();
            }
        }


        /**
         * @method document
         * @listen selector#click.zg.changeLanguage When you click on selector of the plugin
         * */
        $( selector ).on( 'click.zg.changeLanguage', languageLinks, function ( e ) {
            e.preventDefault();

            changeLanguage( $(this).data('lang') );
        });
    });
})(jQuery);
