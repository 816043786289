(function ($) {
    'use strict';

    var root = this;


    /**
     *
     * @param name
     * @param value
     * @param days
     */
    root.zgCreateCookie = function (name, value, days) {
        var expires = '';

        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));

            expires = '; expires=' + date.toGMTString();
        }

        document.cookie = name + '=' + value + expires + '; path=/';
    };


    /**
     *
     * @param name
     * @returns {*}
     */
    root.zgReadCookie = function (name) {
        var nameEQ = name + '=';
        var ca     = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }
        return null;
    };


    /**
     *
     * @param name
     */
    root.zgEraseCookie = function (name) {
        root.zgCreateCookie(name, '', -1);
    };


    // cookie policy alert and cookies
    $(function () {
        var cookieAlert      = '[data-zg-role="cookie-alert"]',
            cookieName       = 'ZG-cookiepolicy-' + window.SGL_JS_THEME,
            cookieFileName   = 'cookie_policy.html',
            containerContent = 'content_cookie_policy';

        // CHECK IF THERE IS COOKIE
        if (!root.zgReadCookie(cookieName)) {

            root.loadAjaxFile(cookieFileName, containerContent);

            $(document).on('zg.loadAjaxFile.success', function (e, fileName) {
                if (cookieFileName === fileName) {
                    var $cookiepolicy = $(document).find( cookieAlert );

                    $cookiepolicy.on('close.bs.alert', function () {
                        root.zgCreateCookie(cookieName, 'ZG Cookies Policy Accepted', 30);
                    });
                    $cookiepolicy.removeClass('hidden');
                }
            });

        }
    });

}).call(this, jQuery);
