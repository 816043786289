/**
 * zg-pcapredic.js
 *
 * Plugin for manage e-voucher and use in cart

 *
 * @author: @author: Fabio Polizzi <fpolizzi [at] koooomo [dot] com>
 *
 */

/* global  JS_TRANSLATIONS */

(function ($, _) {

    'use strict';

    var root = this;

    /**
     * @selector The plugin start if there is the selector in the dom when the page load
     */

    var selector = '#Register_tab,#Guest_tab,#address_form_modal,#addressForm';
    var control;

    var pca_key;
    if( typeof SGL_JS_PCA_KEY !== 'undefined' && SGL_JS_PCA_KEY ) pca_key = SGL_JS_PCA_KEY;

    var options = {
        key: pca_key,
        setCountryByIP: true
    };

    var DEFAULTS = {
        //SELECTOR
        countrySelect:        '[data-zg-role="country-id"]',
        stateSelect:          '[data-zg-role="state-id"]'
    };

    /**
     *
     * @param {HTMLElement} element
     * @param {!object}     options
     *
     * @constructor
     */

    var Pcapredict = function ( element, options ) {

        this.$element                   = $( element );
        this.options                    = _.extendOwn( _.clone( DEFAULTS ), options || {} );

        this.$countrySelect           = $( this.options.countrySelect );
        this.$stateSelect             = $( this.options.stateSelect );

        this.__init();
        this.__eventHandlers();
    };

    Pcapredict.prototype.__init = function () {

        var that = this;
				
				return false; // Tomas - removing Loqate altogether
			
        $("input").attr('autocomplete', 'off');


        if( that.$element.attr('id') == 'Register_tab' ){
            var fields = [
                { element: "registershipping_address_addr_1", field: "Line1" },
                { element: "registershipping_address_addr_2", field: "Line2", mode: pca.fieldMode.POPULATE },
                { element: "registershipping_address_city", field: "City", mode: pca.fieldMode.POPULATE },
                { element: "registershipping_address_country_id", field: "ProvinceName", mode: pca.fieldMode.POPULATE },
                { element: "registershipping_address_post_code", field: "PostalCode" },
                { element: "registershipping_address_state_id", field: "CountryName", mode: pca.fieldMode.SEARCH }
            ];
        }

        if( that.$element.attr('id') == 'Guest_tab' ){
            var fields = [
                { element: "guestshipping_address_addr_1", field: "Line1" },
                { element: "guestshipping_address_addr_2", field: "Line2", mode: pca.fieldMode.POPULATE },
                { element: "guestshipping_address_city", field: "City", mode: pca.fieldMode.POPULATE },
                { element: "guestshipping_address_country_id", field: "ProvinceName", mode: pca.fieldMode.POPULATE },
                { element: "guestshipping_address_post_code", field: "PostalCode" },
                { element: "guestshipping_address_state_id", field: "CountryName", mode: pca.fieldMode.POPULATE }
            ];
        }

        if( that.$element.attr('id') == 'addressForm' ){
            var fields = [
                {element: "addr_1", field: "Line1"},
                {element: "addr_2", field: "Line2", mode: pca.fieldMode.POPULATE},
                {element: "city", field: "City", mode: pca.fieldMode.POPULATE},
                {element: "country_id", field: "ProvinceName", mode: pca.fieldMode.POPULATE},
                {element: "post_code", field: "PostalCode"},
                {element: "state_id", field: "CountryName", mode: pca.fieldMode.COUNTRY}
            ];
        }

        that.__initPca( fields );

    };


    Pcapredict.prototype.__fix_contry = function ( address ) {

        var that = this;

        var data = {
            country: address.CountryName || '',
            state:   address.ProvinceName || address.City || ''
        };

        zgGet( 'convertPCA', data, null, {
            success: (function ( response ) {
                that.$element.find( that.options.countrySelect ).val( response.countryId ).trigger('change');
                setTimeout(function(){
                    that.$element.find( that.options.stateSelect ).val( response.stateId ).trigger('change');
                }, 500);
            }),
            error: (function ( response ) {
                var val = $('option:contains(' + address.CountryName +')').val();
                that.$element.find( that.options.countrySelect ).val( val ).trigger('change');
                setTimeout(function(){
                    var val = $('option:contains(' + address.ProvinceName +')').val();
                    that.$element.find( that.options.stateSelect ).val( val ).trigger('change');
                }, 500);
            })
        } );

    };



    Pcapredict.prototype.__initPca = function ( fields ) {
        var that = this;
        var control = new pca.Address(fields, options);
        control.listen("populate",
            function(address) {
                that.__fix_contry( address );
            }
        );

        var isChrome = !!window.chrome && !!window.chrome.webstore;
        if (isChrome == true){
            for (var i=0; control.fields.length > i; i++){
                $(document).on('focus click', '#' + control.fields[i].element ,  function(e){
                    $( this ).attr("autocomplete","new-password");
                });
            };
        };

    };


    Pcapredict.prototype.__eventHandlers = function () {
        var that = this;
        if( that.$element.attr('id') == 'address_form_modal' ) {
            $(document).on('zg.addressForm.ready', function (data) {
                var fields = [
                    {element: "addr_1", field: "Line1"},
                    {element: "addr_2", field: "Line2", mode: pca.fieldMode.POPULATE},
                    {element: "city", field: "City", mode: pca.fieldMode.POPULATE},
                    {element: "country_id", field: "ProvinceName", mode: pca.fieldMode.POPULATE},
                    {element: "post_code", field: "PostalCode"},
                    {element: "state_id", field: "CountryName", mode: pca.fieldMode.COUNTRY}
                ];
                that.__initPca( fields );
            });
        }

    };

    // Pcapredict PLUGIN DEFINITION
    // =========================
    function Plugin ( option ) {
        return this.each( function () {
            var $this   = $( this );
            var data    = $this.data( 'zg.pcapredict' );
            var options = $.extend( {}, root.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

            if ( !data ) {
                $this.data( 'zg.pcapredict', new Pcapredict( this, options ) );
            }
        } );
    }

    $.fn.zg_pcapredict             = Plugin;
    $.fn.zg_pcapredict.Constructor = Pcapredict;

    //START PLUGIN
    $( function () {
        if( typeof SGL_JS_PCA_KEY !== 'undefined' && SGL_JS_PCA_KEY ){
            $( selector ).each( function () {
                Plugin.call( $( this ) );
            } );
        }
    } );


}).call( this, jQuery, _ );
