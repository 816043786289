/**
 *
 * Plugin for manage product bundle
 *
 * @author: Fabio Polizzi <fpolizzi[at]zerogrey[dot]com>
 *
 */



(function ( $, _ ) {
    'use strict';

    /**
     * @selector data-zg-role="product-bundle" The plugin start if there is the selector in the dom when
     *     the page load
     */
    var selector = '[data-zg-role="product-bundle"]';


    // PRODUCT CLASS DEFINITION
    // ========================

    /**
     *
     * @param {HTMLElement} element
     * @param {!object}     options
     *
     * @constructor
     */
    var ProductBundle = function ( element, options ) {
        this.$element = $( element );

        this.options = _.clone( ProductBundle.DEFAULTS );
        this.setOptions( options );



        this.product = null;

        // basic script configuration
        this.options.namespace = this.options.namespace || _.uniqueId();

        this.availability        = null;
        this.isAvailable         = true;
        this.availabilityChecked = false;

        //this.$modalBody = $( this.options.modalBody );

        // initialize object
        this.setEventHandlers();
        




    };



    ProductBundle.DEFAULTS = {
        target:  '[data-zg-role="bundle-items"]',
        product: '[data-zg-role="product"]',
        elementDreamBag:       '[data-zg-role="dreambag"]',
        elementDreamBagEmail:  '[data-zg-role="dreambag-email"]',
        elementAddToCart:         '[data-zg-role="add-to-cart"]',
        elementQuantity:          '[data-zg-role="quantity-bundle"]',
        elementBundleSelector:    '[data-zg-role="bundle-selector"]',
        elementBundleRule:        '[data-zg-role="bundle-rule"]',
        elementBundleRuleProduct: '[data-zg-role="bundle-product-rule"]',
        elementCombinationBundle: '[data-zg-role="selected-combination-bundle"]',
        checkAvailabilityOnInit: true,
        enableAddToCart:     true,
        checkQuantity: true,
        selectedSku: null,
        selectedProdId: null
    };




    /**
     * Check if products are available and have sku selected
     */
    ProductBundle.prototype.checkProducts = function () {


        var multivalues         = [],
            validProducts       = [],
            notAvailable        = [],
            notOptionsSelected  = [];

        $(document).find( this.options.elementBundleSelector + ":checked, select" + this.options.elementBundleSelector).each( _.bind( function ( key, element ) {

            if( $(element).is("select") ) {
                var zgProduct = $(element).closest( this.options.elementBundleRuleProduct ).find('[data-zg-role="get-product"]:not(.d-none) [data-zg-role="product"]').data('zg.product');

            }else{
                var zgProduct = $(element).closest( this.options.elementBundleRuleProduct ).find('[data-zg-role="product"]').data('zg.product');

            }



            if( zgProduct && ( zgProduct.product.options == null || ( zgProduct.selectedSku && (zgProduct.selectedSku !== 0) ) ) ){
                if( zgProduct.isAvailable ){
                    multivalues[ zgProduct.product.id ] = zgProduct;
                    validProducts.push( multivalues[ zgProduct.product.id ] );
                }
                else if ( zgProduct.availabilityChecked ) {
                    notAvailable.push( zgProduct.product.name );
                }
            }
            else {
                notOptionsSelected.push( zgProduct.product.name );
            }
        }, this ) );

        this.showNotification( 'following.products.not.available', notAvailable );
        this.showNotification( 'following.products.have.no.options.selected', notOptionsSelected );

        var to_return = false;
        if ( notAvailable.length == 0 && notOptionsSelected.length == 0){
            to_return = true;
        }

        return to_return;
    };

    /**
     * Show notification with subject message
     */
    ProductBundle.prototype.showNotification = function ( msg, subject ) {
        if( !subject.length ){
            return false;
        }

        $(document).trigger('zg-error', [{
            message: window.JS_TRANSLATIONS[msg] + ' ' + subject.toString()
        }]);
    };


    /**
     * If the product is available it triggers the request to add to the cart.
     * Otherwise it requests the availability (if unknown) or displays an error msg.
     *
     * @method addToCart
     * @fires document#zg-error the product is not available.
     */
    ProductBundle.prototype.addToCart = function (isSubscription) {

        if ( this.product ) {

            if ( this.isAllBundlesSelected() && this.checkProducts() ) {
                // check if the product is available
                if ( this.isAvailable ) {
                    this.addToCartRequest(isSubscription);
                } else if ( this.availabilityChecked ) {
                    // the product is not available.
                    $( document ).trigger( 'zg-error', [{
                        eventType: 'add-to-cart.not-available',
                        message:   window.JS_TRANSLATIONS['Out of Stock']
                    }] );
                } else {
                    // the availability has not been checked yet.
                    // We call the check and if it is successful addToCart will be triggered again
                    // (once).
                    this.$element.off( 'product-updated-availability.addtocart' );
                    this.$element.one( 'product-updated-availability.addtocart', (function () {
                        this.addToCart();
                    }).bind( this ) );

                    this.checkAvailability();
                }
            }
        }
    };




    /**
     * @method addToCartRequest
     * @fires document#zg-error Custom product make an error
     */

    /**
     * @method addToCartRequest
     * @fires document#zg.product.addedToCart Product added to the cart
     */

    /**
     * @method addToCartRequest
     * @fires document#zg.product.selectedConfiguration. Selected combination trigger. Used for the
     *     exchange product process
     */

    /**
     * Adds the product to the cart.
     *
     */
    ProductBundle.prototype.addToCartRequest = function (isSubscription) {
        var addToCart;
        var callback;
        var customValues;
        var data;
        var i;
        var value;

        data = {
            product_id: this.product.id,
            sku:        this.selectedSku,
            expiry_date:   this.selectedExpiryDate || 0,
            quantity:   this.cleanQuantity() || 1,
            isSubscription: isSubscription,
            bundle_products: this.selectedBundleProducts || 0
        };

        if ( this.product && this.options.enableAddToCart ) {
            addToCart = this.options.enableAddToCart;

            // For custom products:
            if ( this.product.custom_values ) {
                customValues = [];
                value        = null;

                for ( i = 0; i < this.product.custom_values.length && addToCart; i++ ) {
                    value = this.$element.find( '[name="acustom_' + this.product.custom_values[i].id + '"]' ).val() || null;

                    if ( value ) {

                        customValues.push( {
                            customization_id:    this.product.custom_values[i].id,
                            customization_value: value
                        } );

                    } else if ( this.product.custom_values[i].is_mandatory ) {
                        addToCart = false;

                        $( document ).trigger( 'zg-error', [{
                            eventType: 'custom-product',
                            message:   JS_TRANSLATIONS.product_customizationError
                        }] );
                    }
                }

                data = $.extend( {}, data, { aCustom: customValues } );
            }

            // Add to the cart
            if ( addToCart ) {
                    
                callback = {
                    success: (function ( data, status ) {
                        // product successfully added  \o/

                        this.updateButton();
                        this.cleanQuantity( 1 );

                        $( document ).trigger( 'zg.product.addedToCart', [status, this.product, data] );

                        if ( this.options.productReloadPageOnAdd ) {
                            window.location.reload();
                        }



                    }).bind( this, data ),

                    error: function () {
                        // Something went wrong. Request the cart items again.
                        $( document ).trigger( 'zg.getProductList.request-info', ['cart'] );
                    }
                };

                // disable the addToCart element.
                $( this.options.elementAddToCart, this.$element ).prop( 'disabled', true );

                zgPost( 'addProductToCart', data, null, callback );
            }
        }

        // selected combination trigger. Used for the exchange product process
        $( document ).trigger( 'zg.product.selectedConfiguration.' + this.options.namespace, [data] );
    };


    /**
	 * Check if the product is currently available in the store.
	 *
	 * Based on the selected SKU and the quantity, make sure that the amount
	 * requested by the user is available in the store.
	 *
	 */
	ProductBundle.prototype.checkAvailability = function () {
        
	};


    

    /**
     * This will change the value of the quantity inputs.
     * Make sure the quantity interface is a number and is in the acceptable range of values
     * Returns the validated value
     *
     * @param {number=} quantity
     *
     * @returns {number}
     */
    ProductBundle.prototype.cleanQuantity = function ( quantity ) {
        var val       = quantity || 1;
        var $qtyInput = this.$element.find( this.options.elementQuantity );

        if ( $qtyInput.length ) {
            val = $qtyInput.zg_cleanQuantity( quantity, this.options.enableFloatQuantity ).val();
        }

        // return the cleaned value
        return Number(val);
    };



    /**
     *
     * @param {object=} object
     *
     * @returns {array|null}
     */
    ProductBundle.prototype.getImagesGallery = function ( object ) {
        var images = null;

        if ( object ) {
            if ( object.processedImages ) {
                // use the processed images
                images = object.processedImages;
            } else if ( object.images ) {
                if ( this.options.processImages ) {
                    // process the images and store them to be used again
                    images = zgProcessProductImages( object.images );
                    object.processedImages = images;
                } else {
                    // send the original images in an array
                    images = [object.images];
                }
            }
        }

        return images;
    };


    


    /**
     *
     * @returns {boolean}
     */
    ProductBundle.prototype.isAllBundlesSelected = function () {
        var selected = true;
        var that = this;

        $( this.options.elementBundleRule , this.$element ).each( function () {

            var $elementCombination = $( this );
            var maxItems = $(this).data('items');
            var itemSelected = 0;

            itemSelected = $( that.options.elementBundleSelector + ':checked', $elementCombination ).length;
            if (itemSelected == 0){
                itemSelected = $( that.options.elementBundleSelector + ' option:selected', $elementCombination ).length;
            }

            if ( maxItems > itemSelected){
                selected = false;
            }

        } );

        return selected;
    };


    /**
     *
     * @returns {boolean}
     */
    ProductBundle.prototype.isInReplenishment = function () {
        var selected = false;
        var that = this;

        $( this.options.elementBundleRule , this.$element ).each( function () {

            var $elementCombination = $( this );
            var maxItems = $(this).data('items');
            var itemSelected = 0;
            var itemDisabled = 0;
            var nItems = 0;
            var totalItems = 0;

            itemSelected = $( that.options.elementBundleSelector + ':checked', $elementCombination ).length;
            if (itemSelected == 0){
                itemSelected = $( that.options.elementBundleSelector + ' option:selected', $elementCombination ).length;
            }

            if ( maxItems > itemSelected){
                itemDisabled = $( that.options.elementBundleSelector + '.outofstock', $elementCombination ).length;
                totalItems = $( that.options.elementBundleSelector, $elementCombination ).length;
                nItems = itemDisabled + itemSelected;
                if( nItems != totalItems) selected = true;
            }

        } );

        return selected;
    };

    






    /**
     * @method setEventHandlers
     */
    
    ProductBundle.prototype.setEventHandlers = function () {

        var that = this;

        // Add to cart (button)
        this.$element.on( 'click.zg.product.addToCart', this.options.elementAddToCart, (function ( e ) {
            e.preventDefault();
            var $elem = $( e.currentTarget );
            this.addToCart($elem.data( 'is-subscription' ));
        }).bind( this ) );

        // Add to cart (event)
        $( document ).on( 'zg.product.addToCart.' + this.options.namespace, this.addToCart );

        // ---------------------------------------------------------------------

        // Bundle select combination start

        if ( this.$element.find( this.options.elementBundleSelector + ':checked').length || this.$element.find( this.options.elementBundleSelector + ' :selected').length ) {
            this.updateBundle();
        }

        this.$element.on(
            'change.zg.product.selectBundleCombination',
            this.options.elementBundleSelector,
            (function ( e ) {
                e.preventDefault();
                var $elem = $( e.currentTarget );
                if( $elem.is("select") ) {
                    var productId = $elem.find('option:selected').data('id');
                    $elem.closest( this.options.elementBundleRuleProduct ).find('[data-zg-role="get-product"]').addClass('d-none');
                    $elem.closest( this.options.elementBundleRuleProduct ).find('[data-products="' + productId + '"]').removeClass('d-none');
                }
                this.updateBundle();
            }).bind( this )
        );

        // Bundle select combination end


        $( document ).on( 'zg.product.calculateSku', (function ( e, type ) {
            this.options.selectedSku = type.selectedSku;
            this.options.selectedProdId = type.productId;
        }).bind( this ) );

        this.$element.on(
            'change',
            '[data-zg-role="option-selector"]',
            (function ( e ) {
                e.preventDefault();
                var $elem = $( e.currentTarget );

                var $elemBundle = $elem.closest( this.options.elementBundleRuleProduct ).find( this.options.elementBundleSelector);

                if( $elemBundle.is("select") ) {
                    $elem.closest( this.options.elementBundleRuleProduct ).find( this.options.elementBundleSelector + " option:selected").attr('value',this.options.selectedProdId + '.' + this.options.selectedSku );
                }else{
                    $elemBundle.val( this.options.selectedProdId + '.' + this.options.selectedSku );
                }

                this.updateBundle();
            }).bind( this )
        );

        $( document ).on( 'zg.getProductInfo.productCreated', (function ( e, element, options, product ) {
            var $elemBundle = element.closest( this.options.elementBundleRuleProduct );
            var ruleLineItems = $elemBundle.data('quantity');
            $elemBundle.find('[data-zg-role="quantity"]').val(ruleLineItems);

            $( $elemBundle.find('[data-zg-role="product"]') ).on( 'product-updated-availability', (function () {
                var zgProductBundle = $elemBundle.find('[data-zg-role="product"]').data('zg.product');
                var zgProductBundleQtaInCart = this.quantityInCart( zgProductBundle.product.id, 0);
                if(zgProductBundle.availability){
                    var zgProductBundleAvailability = zgProductBundle.availability.quantity - zgProductBundleQtaInCart;
                }else{
                    var zgProductBundleAvailability = zgProductBundle.product.quantity - zgProductBundleQtaInCart;
                }
                var prodIsAvailable = true;
                if( ruleLineItems > zgProductBundleAvailability){prodIsAvailable = false;}
                var originDisabled = $elemBundle.find(that.options.elementBundleSelector).prop( "disabled");
                $elemBundle.find(this.options.elementBundleSelector).prop( "disabled", !prodIsAvailable );
                if(!prodIsAvailable){
                    $elemBundle.css('opacity','0.5');
                    $elemBundle.find(this.options.elementBundleSelector).prop( "checked", false ).addClass('outofstock');
                    if (originDisabled) element.closest( this.options.elementBundleRule ).find( this.options.elementBundleSelector + ':not(:disabled):not(:checked)').prop( "checked", true );
                }else{
                    $elemBundle.css('opacity','1');
                    $elemBundle.find(this.options.elementBundleSelector).removeClass('outofstock');
                }                 
            }).bind( this ) );    

            this.updateBundle();
        }).bind( this ) );


        this.$element.on( 'change.zg.product', this.options.elementQuantity, (function () {
            var quantity     = _.isNumber( quantity ) ? quantity : this.cleanQuantity() || 1;
            $( this.options.elementBundleRuleProduct , this.$element ).each( function () {
                var $ruleLine = $( this );
                var ruleLineItems = $(this).data('quantity');
                var totalQtaLine = quantity * Number(ruleLineItems);
                $ruleLine.find('[data-zg-role="quantity"]').val(totalQtaLine).trigger('change');

                var zgProductBundle = $ruleLine.find('[data-zg-role="product"]').data('zg.product');
                var zgProductBundleQtaInCart = that.quantityInCart( zgProductBundle.product.id, 0);
                var zgProductBundleAvailability = zgProductBundle.availability.quantity - zgProductBundleQtaInCart;
                var prodIsAvailable = true;
                if( totalQtaLine > zgProductBundleAvailability){prodIsAvailable = false;}
                $ruleLine.find(that.options.elementBundleSelector).prop( "disabled", !prodIsAvailable );
                if(!prodIsAvailable){
                    $ruleLine.css('opacity','0.5');
                    $ruleLine.find(that.options.elementBundleSelector).prop( "checked", false );
                    $ruleLine.find(that.options.elementBundleSelector).addClass('outofstock');
                }else{
                    $ruleLine.css('opacity','1');
                    console.log($ruleLine.find(that.options.elementBundleSelector).data('hide'));
                    $ruleLine.find(that.options.elementBundleSelector).removeClass('outofstock');
                    if ($ruleLine.find(that.options.elementBundleSelector).data('hide') == true) {
                        $ruleLine.find(that.options.elementBundleSelector).prop( "checked", true );
                    }
                }    
            } );
            this.updateBundle();
        }).bind( this ) );

        $( document ).ajaxStop(function() {
            setTimeout(function(){
                $( that.options.elementBundleRule , that.$element ).each( function () {
                    var nItems = $( this ).data('items');
                    var i = 0;
                    $( that.options.elementBundleSelector + ":not(:disabled):not(.outofstock)" , $( this ) ).each( function () {
                        if (i < nItems){
                            $( this ).prop( "checked", true );
                            i++;
                        }else{
                            $( this ).prop( "checked", false );
                        }
                    } );   
                } ); 
                that.updateBundle();   
            }, 1500);
        });




        // ---------------------------------------------------------------------

    };


    /**
     *
     * @param {object} options
     */
    ProductBundle.prototype.setOptions = function ( options ) {
        _.extendOwn( this.options, options );

        // cast disabledSkus to array
        if ( _.isString( this.options.disabledSkus ) ) {
            this.options.disabledSkus.split( ',' );
        }

        // make sure that disabledSkus' items are strings
        if ( _.isArray( this.options.disabledSkus ) ) {
            this.options.disabledSkus = _.map( this.options.disabledSkus, function ( item ) {
                return '' + item;
            } );
        }

        // disableOptions should be either boolean or Array
        if ( _.isString( this.options.disableOptions ) ) {
            this.options.disableOptions.split( ',' );
        }

        if ( _.isArray( this.options.disableOptions ) ) {
            this.options.disableOptions = _.map( this.options.disableOptions, function ( item ) {
                if ( !isNaN( item ) ) {
                    item = +item;
                }

                return item;
            } );
        }
    };





    /**
     * Invoked after the product script has been initialized and the event handlers are set.
     * It will set up the product info for the script updating the seo content, selected options,
     * ...
     */

    /**
     * @method setProductInfo
     * @fires document#zg.product.ready The product was ready (gallery is initialized, options are configured, availability is check)
     */

    ProductBundle.prototype.setProductInfo = function ( product ) {
        if ( product ) {
            this.product = product;

            this.availability        = null;
            this.isAvailable         = true;
            this.availabilityChecked = false;

            // initialize the product gallery
            zgGalleries(
                this.$element,
                this.options,
                this.getImagesGallery( product ),
                {
                    productName: product.name
                }
            );

			// the product has no options so the availability would not be triggered
			if ( !product.options && this.options.checkAvailabilityOnInit ) {
				this.checkAvailability();
			}
			
			var that = this;
			if ( parseInt(that.options.minQuantity) > 1 ){
				this.checkAvailability();
				setTimeout(function(){ that.checkDisabledOptionsQta( true ); }, 500);
			}



            $( document ).trigger( 'zg.product.ready', [this] );
        } else if ( DEBUG ) {
            console.warn( 'setProductInfo: No product object provided' );
        }
    };


    /**
     *
     * @param {boolean} [isAvailable]
     */
    ProductBundle.prototype.updateButton = function ( isAvailable, isForced ) {
        if ( _.isNull( isAvailable ) || _.isUndefined( isAvailable ) ) {
            isAvailable = this.isAvailable;
        }

        // enable or disable Add to cart button
        $( this.options.elementAddToCart, this.$element ).prop( 'disabled', !isAvailable );

        
        if(!isAvailable && isForced != true){
            if( this.isInReplenishment() ){
			    $( this.options.elementAddToCart, this.$element ).html( window["JS_TRANSLATIONS"]["select_products"] );
            }else{
                $( this.options.elementAddToCart, this.$element ).html( window["JS_TRANSLATIONS"]["in_restocking"] );
            }
		}else{
				$( this.options.elementAddToCart, this.$element ).html( window["JS_TRANSLATIONS"]["Add to Bag"] );
		}
        

        // show or hide the "dream bag" form
        if ( this.isAvailable ) {
            $( this.options.elementDreamBag, this.$element ).fadeOut();
        } else if ( this.availabilityChecked ) {
            $( this.options.elementDreamBag, this.$element ).fadeIn();
        }
    };











    ProductBundle.prototype.updateBundle = function () {
        var combinationBundle = [];
        var selectedBundleProducts = new Object();
        var that = this;


        $( this.options.elementBundleRule , this.$element ).each( function () {

            var $elementCombination = $( this );
            var valuesCombination = [];
            var selectedBundleProductsSingle = [];
            var maxItems = $(this).data('items');
            var ruleId = $(this).data('id');

            $( that.options.elementBundleSelector, $elementCombination ).closest( that.options.elementBundleRuleProduct ).find('[data-zg-role="product"] select').prop( "disabled", true );

            $( that.options.elementBundleSelector + ':checked', $elementCombination ).each( function () {

                var productId = $(this).data('product');

                $( this ).closest( that.options.elementBundleRuleProduct ).find('[data-products="' + productId + '"] select').prop( "disabled", false );
                var name = $(this).data('value');
                $( this ).closest( that.options.elementBundleRuleProduct ).find('[data-zg-role="selected-combination"] li').each( function () {
                    name = name + " " + $(this).text();
                } );

                valuesCombination.push( {
                    value: name,
                } );
                selectedBundleProductsSingle.push( $(this).val() );
            } );

            $( that.options.elementBundleSelector + ' option:selected', $elementCombination ).each( function (e) {

                var productId = $(this).data('id');
                $( this ).closest( that.options.elementBundleRuleProduct ).find('[data-products="' + productId + '"] select').prop( "disabled", false );

                var name = $(this).data('value');
                $( this ).closest( that.options.elementBundleRuleProduct ).find('[data-zg-role="get-product"]:not(.d-none) [data-zg-role="selected-combination"] li').each( function () {
                    name = name + " " + $(this).text();
                } );

                valuesCombination.push( {
                    value: name,
                } );
                selectedBundleProductsSingle.push( $(this).val() );
            } );

            combinationBundle.push( {
                option: $(this).data('name'),
                values: valuesCombination
            } );

            selectedBundleProducts[ ruleId ] = selectedBundleProductsSingle;

            if (maxItems > 1 && (  $( that.options.elementBundleSelector + ':checked', $elementCombination ).length == maxItems)){
                $( that.options.elementBundleSelector + ':not(:checked)', $elementCombination ).prop('disabled', true);
            }else{
                $( that.options.elementBundleSelector + ':not(:checked):not(.outofstock)', $elementCombination ).prop('disabled', false);
            }

            
            //console.log($( that.options.elementBundleSelector + ':not(.outofstock)' , $elementCombination ).length);
            //console.log( $( that.options.elementBundleSelector + ':checked', $elementCombination ).length );
            //console.log(maxItems);

            if ( ($( that.options.elementBundleSelector + ':not(.outofstock)' , $elementCombination ).length == $( that.options.elementBundleSelector + ':checked', $elementCombination ).length ) &&
                 ($( that.options.elementBundleSelector + ':not(.outofstock)' , $elementCombination ).length == maxItems ) &&
                 ($( that.options.elementBundleSelector + ':checked', $elementCombination ).length == maxItems) ){
                     $( that.options.elementBundleSelector , $elementCombination ).attr("disabled", true);
            }

        } );

        this.selectedBundleProducts = selectedBundleProducts;

        $( that.options.elementCombinationBundle )
            .hide()
            .removeClass( 'd-none' )
            .html( handlebarsTemplates.render( 'selected-combination-bundle', combinationBundle ) )
            .fadeIn();

        if( this.isAllBundlesSelected() ){
            this.updateButton();
        }else{
            this.updateButton( false );
        }



    };





    /**
     * returns the nuber of products in the cart for the SKU selected currently
     *
     * @returns {number}
     */
    ProductBundle.prototype.quantityInCart = function ( productToCheck, skuToCheck) {
		var i;
		var lastCart;
		var quantity = 0;

		if ( this.product ) {
			lastCart = window.getLastCart ? window.getLastCart() : null;

			if ( lastCart && lastCart.products_in_cart.length ) {
				for ( i = 0; i < lastCart.products_in_cart.length; i++ ) {
					if (
						lastCart.products_in_cart[i].product_id == productToCheck &&
						lastCart.products_in_cart[i].sku == skuToCheck
					) {
						quantity += +(lastCart.products_in_cart[i].quantity);
					}else{
						if (lastCart.products_in_cart[i].isBundle == true){
							var productBundle = lastCart.products_in_cart[i].bundle_products;
							_(productBundle).each(function (value) {
								_(value).each(function (valueProd) {
									if (
										valueProd.product_id == productToCheck &&
										valueProd.sku == skuToCheck
									) {
										quantity += +(valueProd.quantity);
									}
								})
							})
						}
					}
				}
			}
		}

		return +quantity;
	};


    // PRODUCT PLUGIN DEFINITION
    // =========================

    function Plugin ( option, product ) {
        return this.each( function () {
            var $this   = $( this );
            var data    = $this.data( 'zg.productBundle' );
            var options = $.extend(
                {},
                ProductBundle.DEFAULTS,
                window.ZG_CONFIG || {},
                $this.data(),
                typeof option === 'object' && option
            );

            product = product || options.product || null;
            delete( options.product );

            if ( !data ) {
                $this.data( 'zg.productBundle', (data = new ProductBundle( this, options )) );
            } else if ( typeof option === 'object' ) {
                data.setOptions( options );
            }

            if ( product ) {
                data.setProductInfo( product );
            }
        } );
    }

    $.fn.zg_productBundle             = Plugin;
    $.fn.zg_productBundle.Constructor = ProductBundle;





    $( function () {
        $( selector ).each( function () {
            Plugin.call( $( this ) );
        } );
    } );

}( jQuery, _ ));